import {
  createInfiniteQuery as queryKitCreateInfiniteQuery,
  createQuery as queryKitCreateQuery,
  type CreateInfiniteQueryOptions,
  type CreateQueryOptions,
} from "react-query-kit";
import type { z as zod } from "zod";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createQuery<TVariables = any>() {
  return <TData, TError = unknown>(
    options: CreateQueryOptions<TData, TVariables, TError>
  ) => queryKitCreateQuery<TData, TVariables, TError>(options);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createInfiniteQuery<TVariables = any>() {
  return <TData, TError = unknown>(
    options: CreateInfiniteQueryOptions<TData, TVariables, TError>
  ) => queryKitCreateInfiniteQuery<TData, TVariables, TError>(options);
}

export interface CreateQueryWithSchemaOptions<TData, TVariables, TError>
  extends Omit<CreateQueryOptions<TData, TVariables, TError>, "queryFn"> {
  queryFn: CreateQueryOptions<TData, TVariables, TError>["queryFn"];
}

export function createQueryWithSchema<TSchema extends zod.ZodSchema>(
  schema: TSchema
) {
  type TData = zod.infer<TSchema>;

  return <TVariables, TError = unknown>({
    queryFn,
    ...rest
  }: CreateQueryWithSchemaOptions<TData, TVariables, TError>) => {
    return queryKitCreateQuery<TData, TVariables, TError>({
      ...rest,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      queryFn: async (...args) => schema.parse(await queryFn(...args)),
    });
  };
}

export interface CreateInfiniteQueryWithSchemaOptions<TData, TVariables, TError>
  extends Omit<
    CreateInfiniteQueryOptions<TData, TVariables, TError>,
    "queryFn"
  > {
  queryFn: CreateInfiniteQueryOptions<unknown, TVariables, TError>["queryFn"];
}

export function createInfiniteQueryWithSchema<TSchema extends zod.ZodSchema>(
  schema: TSchema
) {
  type TData = zod.infer<TSchema>;

  return <TVariables, TError = unknown>({
    queryFn,
    ...rest
  }: CreateInfiniteQueryWithSchemaOptions<TData, TVariables, TError>) => {
    return queryKitCreateInfiniteQuery<TData, TVariables, TError>({
      ...rest,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      queryFn: async (...args) => schema.parse(await queryFn(...args)),
    });
  };
}
