import { useDeepCompareMemo } from "use-deep-compare";

import useMatchMedia from "./useMatchMedia";

export type UseBreakpointOptions = { fallback?: boolean } & (
  | {
      min: number;
      max: number;
    }
  | { max: number }
  | { min: number }
);

export default function useBreakpoint({
  fallback,
  ...rest
}: UseBreakpointOptions) {
  const media = useDeepCompareMemo(() => {
    return Object.entries(rest)
      .map(([key, value]) => `(${key}-width: ${value}px)`)
      .join(" and ");
  }, [rest]);

  return useMatchMedia(media, fallback);
}
