import { z as zod } from "zod";

export function successfulResponse<T extends zod.ZodTypeAny>(data: T) {
  return (
    zod
      .object({
        success: zod.literal(true),
        data,
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .transform(({ data }): zod.infer<T> => data)
  );
}
