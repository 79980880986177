import { useEffect, useInsertionEffect, useRef } from "react";

export interface UseCloseWatcherOptions {
  onClose: () => void;
  disabled?: boolean;
}

export default function useCloseWatcher({
  onClose,
  disabled,
}: UseCloseWatcherOptions) {
  const onCloseRef = useRef(onClose);

  useInsertionEffect(() => {
    onCloseRef.current = onClose;
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (disabled || !("CloseWatcher" in window)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    const watcher = new (window.CloseWatcher as any)();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, unicorn/prefer-add-event-listener
    watcher.onclose = () => {
      onCloseRef.current();
    };

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      watcher.destroy();
    };
  }, [disabled]);
}
