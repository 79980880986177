import { type PropsWithChildren } from "react";

import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useSearchParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { shallow } from "zustand/shallow";

import CollapseIcon from "@rls/icons/chevron-down";

import { flyDown as ctFlyDown } from "@rls/styles/transitions.css";

import DropdownRadio from "@/components/dropdown-radio/DropdownRadio";
import useCohortSelectorState from "@/features/course-menu/context/cohort-selector-state/useCohortSelectorState";
import useCourseSelectedCohortInfo from "@/features/course-menu/context/selected-cohort-info/useCourseSelectedCohortInfo";
import { useCourseParam } from "@/hooks/params";
import type { CourseMenuCohort } from "@/schemas/course/menu/menu";
import { useUserSelectedCohortStore } from "@/stores/user-selected-cohort";
import { addTransitionEndListener } from "@/utils/transition";
import withCondition from "@/utils/withCondition";

import CourseMenuSearch from "../../constants/search";

import * as styles from "./CourseMenuHeadline.css";

export interface CourseMenuHeadlineProps extends PropsWithChildren {
  cohorts: CourseMenuCohort[];
}

const CourseMenuHeadline = withCondition(
  function CourseMenuHeadline({ cohorts, children }: CourseMenuHeadlineProps) {
    const course = useCourseParam();

    const { isOpen, setOpen } = useCohortSelectorState();
    const [, setSearch] = useSearchParams();

    const { floatingStyles, context, refs } = useFloating({
      open: isOpen,
      onOpenChange: setOpen,
      placement: "bottom-start",
      middleware: [offset(16), shift()],
      whileElementsMounted: autoUpdate,
      transform: false,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useDismiss(context),
      useClick(context),
    ]);

    const { selected, change } = useUserSelectedCohortStore(
      ({ change, selected }) => ({
        selected: selected[course],
        change,
      }),
      shallow
    );

    const realCohort = useCourseSelectedCohortInfo()?.id;

    const resolvedCohort = selected ?? realCohort;

    const handleSelect = (id: number) => {
      change(course, id);
      setOpen(false);
      setSearch(
        (params) => {
          params.delete(CourseMenuSearch.selectedCohort);

          return params;
        },
        {
          preventScrollReset: true,
          replace: true,
        }
      );
    };

    return (
      <div className={styles.container} ref={refs.setReference}>
        {children}

        <button className={styles.toggle} {...getReferenceProps()}>
          <CollapseIcon />
        </button>

        <FloatingFocusManager context={context} modal={false}>
          <CSSTransition
            in={isOpen}
            mountOnEnter
            unmountOnExit
            classNames={ctFlyDown}
            addEndListener={addTransitionEndListener}
          >
            <div
              ref={refs.setFloating}
              className={styles.cohortsWrapper}
              style={floatingStyles}
            >
              <DropdownRadio
                {...getFloatingProps()}
                className={styles.cohorts}
                options={cohorts}
                selectLabel={({ course_version_name, cohort_name }) =>
                  `${course_version_name}/${cohort_name}`
                }
                selectValue={({ id }) => id}
                onSelect={handleSelect}
                selectedValue={resolvedCohort}
              />
            </div>
          </CSSTransition>
        </FloatingFocusManager>
      </div>
    );
  },
  ({ cohorts }) => cohorts.length >= 2,
  { ifFalse: "children" }
);

export default CourseMenuHeadline;
