import { z as zod } from "zod";

import { zodDate } from "../helpers";

export const playlistSchema = zod.object({
  id: zod.number(),
  image: zod.string().nullish(),
  name: zod.string(),
  available: zod.boolean(),
});

export const playlistsRequestSchema = zod.array(playlistSchema);

export type CoursePlaylist = zod.infer<typeof playlistSchema>;

// NOTE: although we can extend `courseNewVideoSchema` schema from paid course page
// we should avoid this as format may change in future
export const playlistVideoSchema = zod.object({
  id: zod.number(),
  preview_image: zod.string().nullish(),
  duration: zod.string(),
  name: zod.string(),
  recorded_at: zodDate,
  speakers: zod.array(
    zod.object({
      id: zod.number().optional(),
      name: zod.string(),
    })
  ),
  watched: zod.boolean(),
  available: zod.boolean(),
  new: zod.boolean(),
  favorite: zod.boolean(),
});

export const videosPageSchema = zod.object({
  videos: zod.array(playlistVideoSchema),
  nextPage: zod.number().nullable(),
});

export type CourseVideo = zod.infer<typeof playlistVideoSchema>;

export type CourseVideoPage = zod.infer<typeof videosPageSchema>;

export const coursePlaylistSpeakerSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export const coursePlaylistSpeakersResponseSchema = zod.array(
  coursePlaylistSpeakerSchema
);

export type CoursePlaylistSpeaker = zod.infer<
  typeof coursePlaylistSpeakerSchema
>;

export const coursePlaylistTagSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export const coursePlaylistTagsResponseSchema = zod.array(
  coursePlaylistTagSchema
);

export type CoursePlaylistTag = zod.infer<typeof coursePlaylistTagSchema>;
