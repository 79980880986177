import {
  createContext,
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from "react";

export interface IMenuStateContext {
  isOpen: boolean;
  toggle: () => void;
  change: Dispatch<SetStateAction<boolean>>;
}

export const MenuStateContext = createContext<IMenuStateContext>({
  isOpen: false,
  toggle: () => {},
  change: () => {},
});

export function MenuStateProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((v) => !v);
  }, []);

  const contextValue = useMemo<IMenuStateContext>(
    () => ({
      isOpen,
      toggle,
      change: setIsOpen,
    }),
    [isOpen, toggle]
  );

  return (
    <MenuStateContext.Provider value={contextValue}>
      {children}
    </MenuStateContext.Provider>
  );
}
