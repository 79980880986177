import HeaderContent from "./content/HeaderContent";
import HeaderLogo from "./logo/HeaderLogo";

import { header } from "./Header.css";

export default function Header() {
  return (
    <header className={header}>
      <HeaderLogo />

      <HeaderContent />
    </header>
  );
}
