import isNullish from "@/utils/checks/isNullish";

export function safeParseNumber(value: string | null | undefined) {
  if (isNullish(value)) {
    return undefined;
  }

  const parsed = Number.parseInt(value, 10);

  if (Number.isNaN(parsed)) {
    return undefined;
  }

  return parsed;
}
