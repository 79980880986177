import { createContext, useMemo, type PropsWithChildren } from "react";

import type { CourseMenu, CourseMenuCohort } from "@/schemas/course/menu/menu";
import extendTaskModules, {
  type ExtendedCourseMenuTaskModule,
} from "@/utils/task-modules/transform";

export interface ICourseInfoContext {
  name: string;
  modules: ExtendedCourseMenuTaskModule[];
  statuses: {
    testIsEnabled: boolean;
    testIsPassed: boolean;
    testIsMandatory: boolean;
    courseIsPaid: boolean;
    isCanPay: boolean;
    isSuperUser: boolean;
    hasChatIntegration: boolean;
  };
  cohorts: CourseMenuCohort[];
  defaultCohort: CourseMenu["default_cohort"];
}

export const CourseInfoContext = createContext<ICourseInfoContext | undefined>(
  undefined
);

export function CourseInfoProvider({
  children,
  value: {
    course_name,
    task_modules,
    statuses,
    cohorts,
    default_cohort,
    show_pay_button,
  },
}: PropsWithChildren<{ value: CourseMenu }>) {
  const contextValue = useMemo<ICourseInfoContext>(
    () => ({
      name: course_name,
      modules: task_modules ? extendTaskModules(task_modules) : [],
      statuses: {
        testIsEnabled: statuses.test_is_enabled ?? false,
        testIsPassed: statuses.test_is_passed ?? true,
        testIsMandatory: statuses.test_is_mandatory ?? false,
        courseIsPaid: statuses.course_is_paid,
        isCanPay: show_pay_button ?? true,
        isSuperUser: statuses.is_super_user ?? false,
        hasChatIntegration: statuses.has_chat_integration ?? true,
      },
      cohorts,
      defaultCohort: default_cohort,
    }),
    [
      course_name,
      task_modules,
      statuses.test_is_enabled,
      statuses.test_is_passed,
      statuses.test_is_mandatory,
      statuses.course_is_paid,
      statuses.is_super_user,
      statuses.has_chat_integration,
      show_pay_button,
      cohorts,
      default_cohort,
    ]
  );

  return (
    <CourseInfoContext.Provider value={contextValue}>
      {children}
    </CourseInfoContext.Provider>
  );
}
