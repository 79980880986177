import type { PropsWithChildren } from "react";

import type { PropsWithClassName } from "@/utils/props";

export type PageLayoutMenuProps = PropsWithChildren & PropsWithClassName;

export default function PageLayoutMenu({
  children,
  className,
}: PageLayoutMenuProps) {
  return <aside className={className}>{children}</aside>;
}
