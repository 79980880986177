import type { PropsWithChildren } from "react";

import { cx } from "classix";

import type { PropsWithClassName } from "@/utils/props";

import PageLayoutContent from "./content/PageLayoutContent";
import PageLayoutMenu from "./menu/PageLayoutMenu";

import { page } from "./PageLayout.css";

export type PageLayoutProps = PropsWithChildren & PropsWithClassName;

export default function PageLayout({ children, className }: PageLayoutProps) {
  return <article className={cx(page, className)}>{children}</article>;
}

PageLayout.Menu = PageLayoutMenu;
PageLayout.Content = PageLayoutContent;
