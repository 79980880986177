import { match } from "ts-pattern";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export type Theme = "dark" | "light";

export interface ThemeState {
  readonly value: Theme;

  readonly toggle: () => void;
  readonly change: (newTheme: Theme) => void;
}

function getInitialTheme(): Theme {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return "dark";
  }

  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  }

  if (window.matchMedia("(prefers-color-scheme: light)").matches) {
    return "light";
  }

  return "dark";
}

const useThemeStore = create<ThemeState>()(
  persist(
    (set, get) => ({
      value: getInitialTheme(),

      toggle: () =>
        set({
          value: match<Theme, Theme>(get().value)
            .with("dark", () => "light")
            .with("light", () => "dark")
            .exhaustive(),
        }),
      change: (newTheme) => set({ value: newTheme }),
    }),
    {
      name: "theme-store",
    }
  )
);

export const useActiveTheme = () => useThemeStore((state) => state.value);
export const useToggleTheme = () => useThemeStore((state) => state.toggle);
export const useTheme = () =>
  useThemeStore(
    ({ value, toggle }) => ({
      theme: value,
      toggle,
    }),
    shallow
  );

export default useThemeStore;
