import { createMutation } from "react-query-kit";

import { kyFetcher } from "@/api/fetcher";
import { notificationsResponseSchema } from "@/schemas/notifications";
import { successfulResponse } from "@/schemas/response";
import { createQueryWithSchema } from "@/utils/fetch/createQueryFunction";

export const useNotificationsQuery = createQueryWithSchema(
  successfulResponse(notificationsResponseSchema)
)({
  primaryKey: "notifications/list",
  queryFn: async () => {
    return await kyFetcher.get("notifications").json();
  },
});

export const useReadAllMutation = createMutation({
  mutationFn: () => {
    return kyFetcher.put("notifications/read-all");
  },
});
