import type { ReactNode } from "react";

import { cx } from "classix";
import { Helmet } from "react-helmet-async";

import { Container } from "@rls/ui-kit";

import { pageTitleTemplate } from "@/utils/pageTitle";
import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./ErrorLayout.css";

export interface ErrorLayoutProps extends PropsWithClassName {
  pageTitle: string;
  contentClassName?: string;
  children: ReactNode;
}

export default function ErrorLayout({
  pageTitle,
  className,
  contentClassName,
  children,
}: ErrorLayoutProps) {
  return (
    <>
      <Helmet titleTemplate={pageTitleTemplate}>
        <title>{pageTitle}</title>
      </Helmet>
      <Container className={className}>
        <div className={cx(styles.content, contentClassName)}>{children}</div>
      </Container>
    </>
  );
}
