import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useIsLoggedIn } from "@/stores/auth";

export default function ResetQueryCache() {
  const isLoggedIn = useIsLoggedIn();
  const client = useQueryClient();

  useEffect(() => {
    if (!isLoggedIn) {
      client.clear();
    }
  }, [client, isLoggedIn]);

  return null;
}
