import { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Link, useRouteError } from "react-router-dom";

import CustomError from "@/components/errors/custom-error/CustomError";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";

import ErrorInfo from "../stack/ErrorInfo";

import * as styles from "./UnexpectedError.css";

export interface UnexpectedErrorProps {
  reset?: () => void;
}

export default function UnexpectedError({ reset }: UnexpectedErrorProps) {
  const { t } = useTranslation(TranslationNamespace.ERROR, {
    keyPrefix: TranslationKeyPrefix.ERROR_UNEXPECTED,
  });

  const error = useRouteError();

  useEffect(() => {
    console.warn("unexpected error:", error);
  }, [error]);

  return (
    <CustomError
      title={t("page")}
      heading={t("title")}
      text={
        <Trans
          t={t}
          i18nKey="text"
          components={{
            "refresh-button": (
              <button
                className={styles.actionButton}
                onClick={() => window.location.reload()}
              />
            ),
            "main-page": (
              <Link className={styles.actionButton} to="/" onClick={reset} />
            ),
          }}
        />
      }
      info={<ErrorInfo error={error} />}
    />
  );
}
