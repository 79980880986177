import useThemeStore, { type Theme } from "./stores/theme";

function applyTheme(theme: Theme) {
  document.documentElement.dataset["theme"] = theme;
}

applyTheme(useThemeStore.getState().value);

useThemeStore.subscribe(({ value: theme }, { value: prevTheme }) => {
  if (theme !== prevTheme) {
    applyTheme(theme);
  }
});
