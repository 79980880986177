import { useEffect } from "react";

import { useHref, useLocation } from "react-router-dom";

import useRedirectUrlStore from "@/stores/redirect-url";

export default function SaveRedirectUrl() {
  const location = useHref(useLocation());

  useEffect(() => {
    useRedirectUrlStore.getState().save(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
