import { useEffect } from "react";

import { nanoid } from "nanoid";

const id = nanoid();
let element: HTMLStyleElement | undefined;

function getUsesCount(node: HTMLElement) {
  return Number.parseInt(node.dataset["uses"] ?? "0") || 0;
}

function createElement() {
  const style = document.createElement("style");
  style.id = id;
  style.textContent = `.${id} { overflow: hidden }`;
  style.dataset["uses"] = "1";
  document.head.append(style);
  return style;
}

export function useLockScroll(isLocked = true) {
  useEffect(() => {
    element ??= createElement();
    element.dataset["uses"] = String(getUsesCount(element) + 1);

    return () => {
      if (!element) {
        return;
      }

      const uses = getUsesCount(element);

      if (uses <= 1) {
        element.remove();
      } else {
        element.dataset["uses"] = String(uses - 1);
      }
    };
  }, []);

  useEffect(() => {
    if (!isLocked) {
      return;
    }

    document.documentElement.classList.add(id);

    return () => {
      document.documentElement.classList.remove(id);
    };
  }, [isLocked]);
}
