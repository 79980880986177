import { Suspense } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ScopedMarkdownEditor } from "@rls/scoped-markdown-components/editor";
import { FormError } from "@rls/ui-kit";

import TranslationNamespace from "@/constants/translation/namespace";

import * as styles from "./HelpQuestionModalEditor.css";

export interface HelpQuestionModalEditorProps {
  name: string;
}

export default function HelpQuestionModalEditor({
  name,
}: HelpQuestionModalEditorProps) {
  const { t } = useTranslation(TranslationNamespace.HELP);

  return (
    <Suspense fallback={<div />}>
      <div className={styles.wrapper}>
        <Controller
          name={name}
          defaultValue=""
          render={({ field }) => (
            <ScopedMarkdownEditor
              value={field.value as string}
              onChange={field.onChange}
              onBlur={field.onBlur}
              onReady={(editor) => {
                // NOTE: refreshing editor after modal animation ends
                setTimeout(() => editor.refresh(), 300);
              }}
              placeholder={t("placeholder")}
              rootProps={{
                className: styles.editor,
              }}
              wrapperProps={{
                style: {
                  height: "100%",
                },
              }}
            />
          )}
        />
        <ErrorMessage name={name} field={name} as={FormError} />
      </div>
    </Suspense>
  );
}
