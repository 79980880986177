import type { ApiSuccessResponse } from "@/types/api";
import type { VariablesType } from "@/types/variables";

import { kyFetcher } from "./fetcher";

type reportMistakeParams = {
  path?: string,
  selectedText?: string,
  comment?: string
}

interface AssociativeArray {
  [key: string]: string
}

const commonApi = {
  async variables() {
    const { data } = await kyFetcher
      .get("variables")
      .json<ApiSuccessResponse<VariablesType>>();
    return data;
  },

  async reportMistake(params: reportMistakeParams): Promise<boolean> {
    const { success } = await kyFetcher
      .post("send-message-about-error", {
        json: params,
      }).json<ApiSuccessResponse<VariablesType>>();

    return success ? true : false;
  },

  async saveUserUtm(utm: AssociativeArray): Promise<boolean> {
    const { success } = await kyFetcher
      .post("user-utm", {
        json: { utm },
      }).json<ApiSuccessResponse<VariablesType>>();

    return success ? true : false;
  },

  // async login(data: ApiLoginPayload) {
  //   return (await fetcher.put("auth", data)).data;
  // },
  // async checkUserData(data: ApiCheckUserDataPayload) {
  //   return (await fetcher.post("check-user-data", data)).data;
  // },
  // async sendVerificationCode(data: ApiSendVerificationCodePayload) {
  //   return (await fetcher.post("send-verification-code", data)).data;
  // },
  // async register(data: ApiRegisterPayload) {
  //   return (await fetcher.post("auth", data)).data;
  // },
  // async csrf() {
  //   return ()
  // }
};

export default commonApi;
