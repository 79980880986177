import { useCallback } from "react";

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface UserSelectedCohortState {
  selected: Record<string, number | undefined>;

  change: (courseUrl: string, cohortId: number) => void;
  resetCohort: (courseUrl: string) => void;
  reset: () => void;
}

export const useUserSelectedCohortStore = create<UserSelectedCohortState>()(
  persist(
    immer((set) => ({
      selected: {},

      change: (courseUrl, cohortId) =>
        set((state) => {
          state.selected[courseUrl] = cohortId;
        }),

      resetCohort: (courseUrl) =>
        set((state) => {
          state.selected[courseUrl] = undefined;
        }),

      reset: () =>
        set((state) => {
          state.selected = {};
        }),
    })),
    {
      name: "selected-cohorts-store",
    }
  )
);
export function useUserSelectedCohort(course: string | null | undefined) {
  return useUserSelectedCohortStore(
    useCallback(
      (state) => (course ? state.selected[course] : undefined),
      [course]
    )
  );
}

useUserSelectedCohort.get = (course: string | null | undefined) => {
  return course
    ? useUserSelectedCohortStore.getState().selected[course]
    : undefined;
};
