import type { BeforeErrorHook } from "ky";

import HTTPWithDataError from "@/errors/http/HTTPWithDataError";

export const kyUnwrapError: BeforeErrorHook = async (error) => {
  try {
    const errorData = await error.response.json();

    // NOTE: you can use both `HTTPWithDataError.data`, and `HTTPError.data`
    return new HTTPWithDataError(error, errorData);
  } catch {
    return error;
  }
};
