import { useEffect, useRef } from "react";

import ProgressBar from "@badrap/bar-of-progress";
import { useNavigation } from "react-router-dom";

import { colors } from "@rls/styles/vars.css";

const progress = new ProgressBar({
  color: colors.aqua.light,
});

export default function PageTransitionProgress() {
  const mountedRef = useRef(false);

  const { state } = useNavigation();

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
      progress.finish();
    };
  }, []);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }

    if (state === "loading") {
      progress.start();
    } else {
      progress.finish();
    }
  }, [state]);

  return null;
}
