import { z as zod } from "zod";

import { zodDate } from "../helpers";

export const conversationSchema = zod.object({
  id: zod.number(),
  question: zod.string(),
  status: zod.string(),
  category: zod.string(),
  updated_at: zodDate,
  thread_link: zod.string().nullable(),
  is_new: zod.boolean().default(true),
});

export const conversationsResponseSchema = zod.object({
  discussions: zod.array(conversationSchema),
  nextPage: zod.number().nullable(),
});

export type ConversationResponse = zod.infer<
  typeof conversationsResponseSchema
>;

export type Conversation = zod.infer<typeof conversationSchema>;

export const conversationCategory = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export type ConversationCategory = zod.infer<typeof conversationCategory>;

export const conversationCategoriesResponse = zod.array(conversationCategory);

export const conversationStatus = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export type ConversationStatus = zod.infer<typeof conversationStatus>;

export const conversationStatusesResponse = zod.array(conversationStatus);
