enum TranslationNamespace {
  DEFAULT = "translation",

  MODAL = "modal",
  REGISTRATION = "registration",
  LOGIN = "login",
  ACTIVATION = "activation",
  RESTORE_PASSWORD = "restore_password",
  CHANGE_PASSWORD = "change_password",
  SOCIAL_CONNECT = "social_connect",
  SERVICE = "service",
  ACCOUNT = "account",
  ACCOUNT_PAYMENTS = "account_payments",
  ACCOUNT_CERTIFICATES = "account_certificates",
  FOOTER = "footer",
  HEADER = "header",
  UNPAID_COURSE = "unpaid_course",
  PAID_COURSE = "paid_course",
  ERROR = "error",
  COURSES = "courses",
  COMMON = "common",
  PAY = "pay",
  TEST = "test",
  VIDEO = "video",
  VIDEO_SECRET = "video_secret",
  VERIFY_EMAIL = "verify_email",
  SIDEBAR_MENU = "sidebar_menu",
  TASKS = "tasks",
  PLUGIN = "plugin",
  SELECT_COHORT = "select_cohort",
  CONVERSATION = "conversation",
  PARAMETERS = "parameters",

  TABLE = "table",

  CALENDAR = "calendar",

  LIVE_CLASSES = "live_classes",
  LIVE_CLASS = "live_class",

  VIDEOS = "videos",

  HELP = "help",

  FORM = "form",
  VALIDATION = "validation",
}

export default TranslationNamespace;
