import TranslationNamespace from "@/constants/translation/namespace";
import customScripts from "@/features/scripts";
import { preloadTranslation } from "@/utils/preload";

export default async function appLayoutLoader() {
  await Promise.all([
    preloadTranslation([
      TranslationNamespace.DEFAULT,
      TranslationNamespace.COMMON,
      TranslationNamespace.ERROR,
      TranslationNamespace.MODAL,
      TranslationNamespace.HEADER,
      TranslationNamespace.FOOTER,
    ]),

    customScripts(),
  ]);

  return null;
}
