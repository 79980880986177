import type { LoaderFunctionArgs } from "react-router-dom";

import queryClient from "@/clients/query";
import TranslationNamespace from "@/constants/translation/namespace";
import { useHelpWidgetInfoQuery } from "@/queries/help";
import { useUserSelectedCohort } from "@/stores/user-selected-cohort";
import { preloadFromQueryKit, preloadTranslation } from "@/utils/preload";
import type { UnwrapLoader } from "@/utils/preload/types";

export default async function helpLoader({
  params: { course },
}: LoaderFunctionArgs) {
  const [initialData] = await Promise.all([
    preloadFromQueryKit(
      queryClient,
      useHelpWidgetInfoQuery,
      course
        ? {
            course,
            cohort: useUserSelectedCohort.get(course),
          }
        : {}
    ).catch(() => null),
    preloadTranslation([TranslationNamespace.HELP]),
  ]);

  return initialData;
}

export type HelpLoaderData = UnwrapLoader<typeof helpLoader>;
