import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";

import Paths from "@/constants/paths";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useCourseSelectedCohortInfo from "@/features/course-menu/context/selected-cohort-info/useCourseSelectedCohortInfo";
import useHasTest from "@/features/course-menu/hooks/useHasTest";
import useIsMandatoryTestNotPassed from "@/features/course-menu/hooks/useIsMandatoryTestNotPassed";
import { useIsNotPaid } from "@/features/course-menu/hooks/useIsNotPaid";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import MenuLink from "@/layouts/menu/default/link/MenuLink";

export default function CourseMenuSectionMaterials() {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_MATERIALS,
  });

  const { course = "" } = useParams();

  const isNotPaid = useIsNotPaid();
  const hasTest = useHasTest();
  const isMandatoryTestNotPassed = useIsMandatoryTestNotPassed();

  const { hasLiveClasses } = useCourseSelectedCohortInfo() ?? {};

  return (
    <SidebarMenu.Section heading={t("heading")}>
      <SidebarMenu.Button
        as={MenuLink}
        disabled={isMandatoryTestNotPassed && isNotPaid}
        to={{
          pathname: generatePath(
            isNotPaid ? Paths.COURSE : Paths.COURSE_ABOUT,
            {
              course,
            }
          ),
        }}
      >
        {t("about")}
      </SidebarMenu.Button>
      <SidebarMenu.Button
        disabled={isMandatoryTestNotPassed && isNotPaid}
        as={MenuLink}
        to={generatePath(Paths.COURSE_VIDEOS, { course })}
      >
        {t("video_materials")}
      </SidebarMenu.Button>
      <SidebarMenu.Button
        as={MenuLink}
        to={generatePath(Paths.COURSE, {
          course,
        })}
        disabled={isNotPaid}
      >
        {t("program")}
      </SidebarMenu.Button>
      {!!hasLiveClasses && (
        <SidebarMenu.Button
          as={MenuLink}
          to={generatePath(Paths.COURSE_LIVE_CLASSES, {
            course,
          })}
          disabled={isNotPaid}
        >
          {t("live_class_schedule")}
        </SidebarMenu.Button>
      )}
      <SidebarMenu.Button
        as={MenuLink}
        to={generatePath(Paths.COURSE_CONVERSATION, {
          course,
        })}
        disabled={isNotPaid}
      >
        {t("conversation")}
      </SidebarMenu.Button>
      {hasTest && (
        <SidebarMenu.Button
          as={MenuLink}
          to={generatePath(Paths.COURSE_TEST, {
            course,
          })}
        >
          {t("entrance_test")}
        </SidebarMenu.Button>
      )}
      <SidebarMenu.Button
        as={MenuLink}
        to={generatePath(Paths.COURSE_PARAMETERS, {
          course,
        })}
        disabled={isNotPaid}
      >
        {t("settings")}
      </SidebarMenu.Button>
    </SidebarMenu.Section>
  );
}
