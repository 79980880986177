import type { ComponentType } from "react";

import { useNotificationsQuery } from "@/queries/notifications";
import type { Notification } from "@/schemas/notifications";

export interface NotificationsLoaderProps {
  Consumer: ComponentType<{ notifications: Notification[]; count: number }>;
}

export default function NotificationsLoader({
  Consumer,
}: NotificationsLoaderProps) {
  const { data: { notifications, new_count: count } = {} } =
    useNotificationsQuery({
      variables: {},
      refetchInterval: 60_000,
    });

  return <Consumer notifications={notifications ?? []} count={count ?? 0} />;
}
