import type { IndexRouteObject, NonIndexRouteObject } from "react-router-dom";

import { neverRevalidate } from "./revalidate";

type Route = IndexRouteObject | NonIndexRouteObject;

export default function defineRoutesConfig<const T extends Route>(
  config: T | T[]
) {
  return (Array.isArray(config) ? config : [config]).map((route) => {
    const { loader, shouldRevalidate } = route;

    if (!loader) {
      return route;
    }

    return {
      ...route,

      // NOTE: since we use react-query, react-router's revalidation doesn't make sense for us
      // However we will keep user-defined `shouldRevalidate` if the user wants to change this behavior
      shouldRevalidate: shouldRevalidate ?? neverRevalidate,
    };
  });
}
