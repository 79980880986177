import { z as zod } from "zod";

const labelSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
  type: zod.union([
    zod.literal("white|black"),
    zod.literal("yellow|orange"),
    zod.literal("light-green|green-herbal"),
    zod.literal("green|blue"),
    zod.literal("red|red"),
  ]),
});

export default labelSchema;

export type Label = zod.infer<typeof labelSchema>;

export const labelsArraySchema = zod.array(labelSchema);
