import { Link, type LinkProps } from "react-router-dom";

import { WithCloseDrawerMenu } from "@/context/menu/close/CloseMenuContext";

export default function MenuLink({ onClick, ...rest }: LinkProps) {
  return (
    <WithCloseDrawerMenu>
      <Link {...rest} />
    </WithCloseDrawerMenu>
  );
}
