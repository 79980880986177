import type { ElementType } from "react";

import { cx } from "classix";

import HeaderButton, {
  type HeaderButtonDefaultElement,
  type HeaderButtonProps,
} from "../HeaderButton";

import { headerTextButton } from "./HeaderTextButton.css";

export default function HeaderTextButton<
  T extends ElementType = typeof HeaderButtonDefaultElement
>(props: HeaderButtonProps<T>) {
  const { className } = props;

  return (
    <HeaderButton {...props} className={cx(headerTextButton, className)} />
  );
}
