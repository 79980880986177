import { z as zod } from "zod";

export const courseFeatureSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
  // TODO: remove this
  // sort: zod.number(),
});

export type CourseFeature = zod.infer<typeof courseFeatureSchema>;
