import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useCourseConversationQuery } from "@/queries/course/conversation";
import { useNotificationsQuery } from "@/queries/notifications";

export default function useInvalidateConversations() {
  const { course } = useParams();

  const queryClient = useQueryClient();

  return useCallback(() => {
    if (course) {
      void queryClient.invalidateQueries({
        queryKey: useCourseConversationQuery.getKey({ course }),
        type: "active",
      });
    }

    void queryClient.invalidateQueries({
      queryKey: useNotificationsQuery.getKey(),
      type: "active",
    });
  }, [course, queryClient]);
}
