import NiceModal from "@ebay/nice-modal-react";
import { Slot } from "nano-slots";
import { useTranslation } from "react-i18next";

import RadioIcon from "@rls/icons/radio";
import ThemeIcon from "@rls/icons/theme";
import QuestionSvg from "@rls/icons/question";

import Slots from "@/constants/slots";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import NotificationsDesktopButton from "@/features/notifications/components/desktop/NotificationsDesktop";
import NotificationsLoader from "@/features/notifications/components/loader/NotificationsLoader";
import useAuthStore from "@/stores/auth";
import { useToggleTheme } from "@/stores/theme";

import HeaderIconButton from "../../button/icon/HeaderIconButton";
import HeaderTextButton from "../../button/text/HeaderTextButton";
import HeaderUserMenu from "../user-menu/HeaderUserMenu";

import * as styles from "./HeaderDesktopContent.css";

export default function HeaderDesktopContent() {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_MENU,
  });

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());

  const toggle = useToggleTheme();

  return (
    <div className={styles.buttons}>
      <HeaderTextButton disabled>{t("open_webinars")}</HeaderTextButton>
      <Slot name={Slots.HEADER_BUTTONS} />
      {isLoggedIn && (
        <NotificationsLoader Consumer={NotificationsDesktopButton} />
      )}
      <HeaderIconButton onClick={toggle}>
        <ThemeIcon />
      </HeaderIconButton>
      {isLoggedIn && <HeaderUserMenu />}
    </div>
  );
}
