import {
  cloneElement,
  createContext,
  useContext,
  type ReactElement,
} from "react";

export type ICloseDrawerMenuContext = () => void;

export const CloseDrawerMenuContext = createContext<ICloseDrawerMenuContext>(
  () => {}
);

export const CloseDrawerMenuProvider = CloseDrawerMenuContext.Provider;

export function useCloseDrawerMenu() {
  return useContext(CloseDrawerMenuContext);
}

export function WithCloseDrawerMenu({
  children,
}: {
  children: ReactElement<{ onClick?: (...args: any[]) => unknown }>;
}) {
  const closeMenu = useCloseDrawerMenu();

  return cloneElement(children, {
    onClick: (...args: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const result = children.props.onClick?.(...args);

      closeMenu();

      return result;
    },
  });
}
