import type { CSSProperties, ElementType } from "react";

import type { PolymorphicPropsWithoutRef } from "@kosmotema/react-polymorphic-types";
import { assignInlineVars } from "@vanilla-extract/dynamic";

import { Text } from "@rls/ui-kit";

import { colors } from "@rls/styles/vars.css";

import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./HelpOption.css";

const HelpOptionDefaultElement = "button";

export interface HelpOptionOwnProps extends PropsWithClassName {
  disabled?: boolean;
  activeColor?: string;
  children: string;
  style?: CSSProperties;
}

export type HelpOptionProps<
  TElement extends ElementType = typeof HelpOptionDefaultElement
> = PolymorphicPropsWithoutRef<HelpOptionOwnProps, TElement>;

export default function HelpOption<
  TElement extends ElementType = typeof HelpOptionDefaultElement
>({ as, activeColor, children, disabled, ...rest }: HelpOptionProps<TElement>) {
  const Element: ElementType = as ?? HelpOptionDefaultElement;

  if (disabled) {
    return (
      <div
        className={styles.disabledButton}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Text
          variant="navigation-secondary"
          className={styles.text}
          color="inherit"
        >
          {children}
        </Text>
      </div>
    );
  }

  return (
    <Element
      {...rest}
      className={styles.button}
      style={assignInlineVars({
        [styles.activeColor]: activeColor ?? colors.aqua.light,
      })}
    >
      <Text
        variant="navigation-secondary"
        className={styles.text}
        color="inherit"
      >
        {children}
      </Text>
    </Element>
  );
}
