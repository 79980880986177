import { memo } from "react";

import { useTranslation } from "react-i18next";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useCourseInfo from "@/features/course-menu/context/course-info/useCourseInfo";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";

import CourseMenuSectionProgramModule from "./module/ProgramModule";

const CourseMenuSectionProgram = memo(function CourseMenuSectionProgram() {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_TASKS,
  });

  const { modules } = useCourseInfo();

  if (modules.length === 0) {
    return null;
  }

  return (
    <SidebarMenu.Section heading={t("heading")}>
      {modules.map(({ id, name, tasks, is_enabled, type, number }) => (
        <CourseMenuSectionProgramModule
          key={id}
          name={name}
          tasks={tasks}
          isEnabled={is_enabled}
          number={number}
          type={type}
        />
      ))}
    </SidebarMenu.Section>
  );
});

export default CourseMenuSectionProgram;
