import { useQueryClient } from "@tanstack/react-query";
import produce from "immer";

import {
  useConversationMarkReadMutation,
  useCourseConversationQuery,
} from "@/queries/course/conversation";
import { useNotificationsQuery } from "@/queries/notifications";
import type { QueryKitData } from "@/types/query/data";

export interface UseMarkReadOptions {
  course: string;
  cohort?: number | undefined;
}

export default function useMarkRead(options: UseMarkReadOptions) {
  const queryClient = useQueryClient();

  const { mutate } = useConversationMarkReadMutation({
    onMutate: ({ id }) => {
      let isNew = false;

      queryClient.setQueriesData<
        QueryKitData<typeof useCourseConversationQuery>
      >(
        {
          queryKey: useCourseConversationQuery.getKey(options),
        },
        (data) => {
          if (!data) {
            return data;
          }

          return produce(data, (draft) => {
            draft.pages.forEach((page) =>
              page.discussions.forEach((discussion) => {
                if (discussion.id === id) {
                  if (discussion.is_new) {
                    isNew = true;
                  }

                  discussion.is_new = false;
                }
              })
            );
          });
        }
      );

      queryClient.setQueriesData<QueryKitData<typeof useNotificationsQuery>>(
        {
          queryKey: useNotificationsQuery.getKey(),
        },
        (data) => {
          if (!data) {
            return data;
          }

          return produce(data, (draft) => {
            draft.notifications.forEach((notification) => {
              if (notification.id === id) {
                if (notification.is_new) {
                  isNew = true;
                }

                notification.is_new = false;
              }
            });

            if (isNew) {
              draft.new_count = Math.max(draft.new_count - 1, 0);
            }
          });
        }
      );
    },

    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: useNotificationsQuery.getKey(),
      });
    },
  });

  return mutate;
}
