import {
  forwardRef,
  type ElementType,
  type PropsWithChildren,
  type Ref,
} from "react";

import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
} from "@kosmotema/react-polymorphic-types";
import { cx } from "classix";

import type { PropsWithClassName } from "@/utils/props";

import { headerButton } from "./HeaderButton.css";

export const HeaderButtonDefaultElement = "button";

export type HeaderButtonOwnProps = PropsWithChildren & PropsWithClassName;

export type HeaderButtonProps<
  TElement extends ElementType = typeof HeaderButtonDefaultElement
> = PolymorphicPropsWithRef<HeaderButtonOwnProps, TElement>;

const HeaderButton: PolymorphicForwardRefExoticComponent<
  HeaderButtonOwnProps,
  typeof HeaderButtonDefaultElement
> = forwardRef(function HeaderButton<
  TElement extends ElementType = typeof HeaderButtonDefaultElement
>({ as, className, ...rest }: HeaderButtonProps<TElement>, ref: Ref<TElement>) {
  const Element: ElementType = as ?? HeaderButtonDefaultElement;

  return (
    <Element {...rest} ref={ref} className={cx(headerButton, className)} />
  );
});

export default HeaderButton;
