// TODO: add support for `replace` object for validation messages
export default class ValidationError extends Error {
  public field: string;
  public override message: string;

  constructor(field: string, message: string) {
    super(`Validation error: ${field} - ${message}`);

    this.field = field;
    this.message = message;
  }
}
