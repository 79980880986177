import { z as zod } from "zod";

import { emailValidation, requiredStringValidation } from "./rules";

export const submitBidSchema = zod.object({
  name: requiredStringValidation,
  email: emailValidation,
  phone: requiredStringValidation,
  comment: requiredStringValidation,
  agreement: zod.literal(true),
});
