import { HTTPError } from "ky";

export default class HTTPWithDataError<T> extends HTTPError {
  public override readonly data: T;

  constructor(error: HTTPError, data: T) {
    super(error.response, error.request, error.options);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    super.data = data;
    this.data = data;
  }
}
