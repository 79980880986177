import { z as zod } from "zod";

export const courseTaskStatuses = ["available", "done", "in-progress"] as const;

const courseTaskStatusSchema = zod.enum(courseTaskStatuses);

export type CourseTaskStatus = zod.infer<typeof courseTaskStatusSchema>;

const courseMenuTaskSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  status: courseTaskStatusSchema.nullish(),
});

export default courseMenuTaskSchema;

export type CourseMenuTask = zod.infer<typeof courseMenuTaskSchema>;

export const courseMenuTaskArraySchema = zod.array(courseMenuTaskSchema);
