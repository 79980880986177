import type { PropsWithChildren } from "react";

import { cx } from "classix";

import type { PropsWithClassName } from "@/utils/props";

import SidebarMenuButton from "./button/SidebarMenuButton";
import SidebarMenuCollapse from "./collapse/SidebarMenuCollapse";
import SidebarMenuSection from "./section/SidebarMenuSection";

import * as styles from "./SidebarMenu.css";

export type SidebarMenuProps = PropsWithChildren & PropsWithClassName;

export default function SidebarMenu({ children, className }: SidebarMenuProps) {
  return <nav className={cx(styles.sidebarMenu, className)}>{children}</nav>;
}

SidebarMenu.Section = SidebarMenuSection;
SidebarMenu.Button = SidebarMenuButton;
SidebarMenu.Collapse = SidebarMenuCollapse;
