import { useRef, useState } from "react";

import {
  arrow,
  autoUpdate,
  FloatingFocusManager,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { CSSTransition } from "react-transition-group";

import NotificationIcon from "@rls/icons/notification";

import { appear, flyDown } from "@rls/styles/transitions.css";

import ArrowSvg from "@/assets/new/popover/arrow.svg?component";
import HeaderIconButton from "@/layouts/base/header/button/icon/HeaderIconButton";
import type { Notification } from "@/schemas/notifications";
import { addTransitionEndListener } from "@/utils/transition";

import NotificationsBottomButtons from "../bottom-buttons/NotificationsBottomButtons";
import NotificationsEmpty from "../empty/NotificationsEmpty";
import NotificationsEntry from "../entry/NotificationsEntry";

import * as styles from "./NotificationsDesktop.css";

export interface NotificationsDesktopButtonProps {
  notifications: Notification[];
  count: number;
}

export default function NotificationsDesktopButton({
  notifications,
  count,
}: NotificationsDesktopButtonProps) {
  const hasNew = count > 0;

  const arrowRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const { floatingStyles, context, refs } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    middleware: [
      size({
        padding: 16,
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
      offset(11),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
    transform: false,
  });

  const dismiss = useDismiss(context);
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const { x, y } = context.middlewareData.arrow ?? {};

  return (
    <>
      <HeaderIconButton ref={refs.setReference} {...getReferenceProps()}>
        <span className={styles.counter({ hasNew })}>
          <NotificationIcon />
          <CSSTransition
            in={hasNew}
            classNames={appear}
            addEndListener={addTransitionEndListener}
            mountOnEnter
            unmountOnExit
          >
            <span className={styles.counterValue}>
              {count >= 100 ? "+" : count}
            </span>
          </CSSTransition>
        </span>
      </HeaderIconButton>
      <FloatingFocusManager context={context} modal={false}>
        <CSSTransition
          in={isOpen}
          mountOnEnter
          unmountOnExit
          classNames={flyDown}
          addEndListener={addTransitionEndListener}
        >
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps({
              className: styles.container,
            })}
          >
            {/* NOTE: this works only when placement is `top` */}
            <ArrowSvg
              ref={arrowRef}
              className={styles.arrow}
              style={{ left: x, top: y }}
            />
            {notifications.length > 0 ? (
              <>
                <ul className={styles.items}>
                  {notifications.map((notification) => (
                    <NotificationsEntry
                      key={notification.id}
                      {...notification}
                    />
                  ))}
                </ul>
                <NotificationsBottomButtons />
              </>
            ) : (
              <NotificationsEmpty className={styles.empty} />
            )}
          </div>
        </CSSTransition>
      </FloatingFocusManager>
    </>
  );
}
