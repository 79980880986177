import type { LoaderFunctionArgs } from "react-router-dom";

import queryClient from "@/clients/query";
import TranslationNamespace from "@/constants/translation/namespace";
import NotFoundError from "@/errors/not-found/NotFoundError";
import { useCourseMenuQuery } from "@/queries/course";
import { useUserSelectedCohortStore } from "@/stores/user-selected-cohort";
import isNullish from "@/utils/checks/isNullish";
import { safeParseNumber } from "@/utils/parse/number";
import { preloadFromQueryKit, preloadTranslation } from "@/utils/preload";
import type { UnwrapLoader } from "@/utils/preload/types";

import CourseMenuSearch from "./constants/search";

export default async function courseMenuLoader({
  params: { course },
  request: { url },
}: LoaderFunctionArgs) {
  if (!course) {
    throw new NotFoundError();
  }

  const requestUrl = new URL(url);

  const cohortParam = safeParseNumber(
    requestUrl.searchParams.get(CourseMenuSearch.selectedCohort)
  );

  const { change, selected } = useUserSelectedCohortStore.getState();

  if (!isNullish(cohortParam)) {
    change(course, cohortParam);
  }

  const selectedCohort = selected[course];

  await preloadTranslation(TranslationNamespace.SIDEBAR_MENU);

  return await preloadFromQueryKit(queryClient, useCourseMenuQuery, {
    url: course,
    cohort: selectedCohort,
  });
}

export type CourseMenuLoaderData = UnwrapLoader<typeof courseMenuLoader>;
