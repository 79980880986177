import type { ShouldRevalidateFunction } from "react-router-dom";

export const revalidateWhenUrlDiffers: ShouldRevalidateFunction = ({
  currentUrl,
  nextUrl,
  defaultShouldRevalidate,
}) => defaultShouldRevalidate && currentUrl.pathname !== nextUrl.pathname;

export const defaultShouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
}) => defaultShouldRevalidate;

export const neverRevalidate = () => false;
