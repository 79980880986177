import { redirect, type RedirectFunction } from "react-router-dom";

import useRedirectUrlStore from "@/stores/redirect-url";

import { extractRelativePathFromUrl, urlWithCurrentSearch } from "../url";

type RedirectFnParams = Parameters<RedirectFunction>;

// TODO: remove `url` param and always use `Paths.LOGIN` as url?
export function unauthorizedRedirect(
  url: RedirectFnParams[0],
  from: URL | string,
  options?: RedirectFnParams[1]
) {
  useRedirectUrlStore.getState().save(extractRelativePathFromUrl(from));
  return redirect(urlWithCurrentSearch(url, from), options);
}
