import type { PropsWithChildren } from "react";

import { Outlet } from "react-router-dom";

import { Breakpoints } from "@rls/styles/media";

import useBreakpoint from "@/hooks/useBreakpoint";
import PageLayout from "@/layouts/page/PageLayout";

import SidebarMenuLayoutDesktop, {
  type SidebarMenuLayoutDesktopProps,
} from "./components/layout/desktop/SidebarMenuLayoutDesktop";
import type { SidebarMenuLayoutHeadingProps } from "./components/layout/heading/SidebarMenuLayoutHeading";
import SidebarMenuLayoutMobile, {
  type SidebarMenuLayoutMobileProps,
} from "./components/layout/mobile/SidebarMenuLayoutMobile";

import * as styles from "./SidebarMenuLayout.css";

export interface SidebarMenuLayoutProps extends PropsWithChildren {
  headline: SidebarMenuLayoutHeadingProps["render"];
  desktop: SidebarMenuLayoutDesktopProps["content"];
  mobile: SidebarMenuLayoutMobileProps["content"];
}

export default function SidebarMenuLayout({
  headline,
  desktop,
  mobile,
  children,
}: SidebarMenuLayoutProps) {
  const isMd = useBreakpoint({
    min: Breakpoints.MD,
  });

  return (
    <PageLayout>
      {isMd ? (
        <SidebarMenuLayoutDesktop headline={headline} content={desktop} />
      ) : (
        <SidebarMenuLayoutMobile headline={headline} content={mobile} />
      )}
      <PageLayout.Content className={styles.content}>
        {children ?? <Outlet />}
      </PageLayout.Content>
    </PageLayout>
  );
}
