import { z as zod } from "zod";

import { zodDate } from "@/schemas/helpers";
import { videoSpeakerSchema } from "@/schemas/video";

export const liveClassTopicSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
});

export type LiveClassTopic = zod.infer<typeof liveClassTopicSchema>;

export const liveClassSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  url: zod.string().nullish(),
  performance_date: zodDate,
  description: zod.string().nullish(),
  video: zod.number().nullish(),
  is_user_enroll: zod.boolean(),
  speakers: zod.array(videoSpeakerSchema),
  topics: zod.array(liveClassTopicSchema),
  cover: zod.string().nullish(),
});

export type LiveClass = zod.infer<typeof liveClassSchema>;
