import { forwardRef, type ElementType, type Ref } from "react";

import { cx } from "classix";

import HeaderButton, {
  type HeaderButtonDefaultElement,
  type HeaderButtonProps,
} from "../HeaderButton";

import { headerIconButton } from "./HeaderIconButton.css";

const HeaderIconButton: typeof HeaderButton = forwardRef(
  function HeaderIconButton<
    TElement extends ElementType = typeof HeaderButtonDefaultElement
  >(
    { className, as, ...rest }: HeaderButtonProps<TElement>,
    ref: Ref<TElement>
  ) {
    const Element: ElementType = HeaderButton;

    return (
      <Element
        {...rest}
        as={as}
        ref={ref}
        className={cx(headerIconButton, className)}
      />
    );
  }
);

export default HeaderIconButton;
