import { useEffect } from "react";

import * as styles from "./useScrollLock.css";

export default function useScrollLock(condition: boolean) {
  useEffect(() => {
    if (!condition) {
      return;
    }

    document.documentElement.classList.add(styles.scrollLock);

    return () => {
      document.documentElement.classList.remove(styles.scrollLock);
    };
  }, [condition]);
}
