import type { PropsWithChildren } from "react";

import { cx } from "classix";

import type { PropsWithClassName } from "@/utils/props";

import { content } from "./PageLayoutContent.css";

export type PageLayoutContentProps = PropsWithChildren & PropsWithClassName;

export default function PageLayoutContent({
  children,
  className,
}: PageLayoutContentProps) {
  return <section className={cx(content, className)}>{children}</section>;
}
