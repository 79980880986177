import type { PropsWithChildren, ReactNode } from "react";

import { Outlet } from "react-router-dom";

import { Breakpoints } from "@rls/styles/media";

import useBreakpoint from "@/hooks/useBreakpoint";
import PageLayout from "@/layouts/page/PageLayout";

import DefaultMenuLayoutDesktop from "./desktop/DefaultMenuLayoutDesktop";
import DefaultMenuLayoutMobile from "./mobile/DefaultMenuLayoutMobile";

import { content } from "./DefaultMenuLayout.css";

export interface DefaultMenuLayoutProps extends PropsWithChildren {
  before?: ReactNode;
  after?: ReactNode;
}

export default function DefaultMenuLayout(props: DefaultMenuLayoutProps) {
  const isMd = useBreakpoint({
    min: Breakpoints.MD,
  });

  return (
    <PageLayout>
      {isMd ? (
        <DefaultMenuLayoutDesktop />
      ) : (
        <DefaultMenuLayoutMobile {...props} />
      )}
      <PageLayout.Content className={content}>
        <Outlet />
      </PageLayout.Content>
    </PageLayout>
  );
}
