import { Breakpoints } from "@rls/styles/media";

import useBreakpoint from "@/hooks/useBreakpoint";

import HeaderDesktopContent from "./desktop/HeaderDesktopContent";
import HeaderMobileContent from "./mobile/HeaderMobileContent";

export default function HeaderContent() {
  const isMd = useBreakpoint({
    min: Breakpoints.MD,
  });

  return isMd ? <HeaderDesktopContent /> : <HeaderMobileContent />;
}
