import { z as zod } from "zod";

export const helpWidgetInfoSchema = zod.object({
  chat_url: zod.string().nullable(),
  // .transform((url) => url ?? "https://t.me/rebrainme"),
  channel_url: zod.string().nullable(),
  // .transform((url) => url ?? "https://rebrain_devops"),
  cohort_chat_url: zod.string().nullish(),
});

export type HelpWidgetInfo = zod.infer<typeof helpWidgetInfoSchema>;
