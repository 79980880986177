import { Suspense, type ReactNode } from "react";

import DefaultMenuLayoutMobile from "@/layouts/menu/default/mobile/DefaultMenuLayoutMobile";

import SidebarMenuLayoutHeading, {
  type SidebarMenuLayoutHeadingProps,
} from "../heading/SidebarMenuLayoutHeading";

export interface SidebarMenuLayoutMobileProps {
  headline: SidebarMenuLayoutHeadingProps["render"];
  content: {
    sections: ReactNode;
    before?: ReactNode;
    after?: ReactNode;
  };
}

export default function SidebarMenuLayoutMobile({
  headline,
  content,
}: SidebarMenuLayoutMobileProps) {
  return (
    <DefaultMenuLayoutMobile before={content.before} after={content.after}>
      <Suspense>
        <SidebarMenuLayoutHeading render={headline} />
        {content.sections}
      </Suspense>
    </DefaultMenuLayoutMobile>
  );
}
