import { Suspense } from "react";

import { Outlet } from "react-router-dom";

// import Footer from "./footer/Footer";
import Header from "./header/Header";

import * as styles from "./BaseLayout.css";

export default function BaseLayout() {
  return (
    // <Suspense>
    <div className={styles.page}>
      <Suspense>
        <Header />
      </Suspense>
      <div className={styles.main}>
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
      {/* <Footer /> */}
    </div>
    // </Suspense>
  );
}
