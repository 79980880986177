import { useCallback, useRef } from "react";

import NiceModal from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import { slideLeftRight as ctSlideLeftRight } from "@rls/styles/transitions.css";

import Paths from "@/constants/paths";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useMenuState from "@/context/menu-state/useMenuState";
import {
  CloseDrawerMenuProvider,
  WithCloseDrawerMenu,
} from "@/context/menu/close/CloseMenuContext";
import SubmitBidModal from "@/features/account/settings/components/modals/submit-bid/SubmitBidModal";
import NotificationsLoader from "@/features/notifications/components/loader/NotificationsLoader";
import NotificationsMobile from "@/features/notifications/components/mobile/NotificationsMobile";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import useScrollLock from "@/hooks/scroll/useScrollLock";
import PageLayout from "@/layouts/page/PageLayout";
import { useIsLoggedIn } from "@/stores/auth";

import type { DefaultMenuLayoutProps } from "../DefaultMenuLayout";
import useLogout from "../hooks/useLogout";
import MenuLink from "../link/MenuLink";
import DefaultMenuLayoutThemeToggle from "../theme-toggle/DefaultMenuLayoutThemeToggle";

import * as styles from "./DefaultMenuLayoutMobile.css";

export default function DefaultMenuLayoutMobile({
  children,
  before,
  after,
}: DefaultMenuLayoutProps) {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_MENU,
  });

  const menuRef = useRef<HTMLDivElement>(null);

  const isLoggedIn = useIsLoggedIn();

  const { isOpen, change } = useMenuState();

  const handleLogout = useLogout();

  const closeMenu = useCallback(() => {
    change(false);
  }, [change]);

  useScrollLock(isOpen);

  return (
    <CloseDrawerMenuProvider value={closeMenu}>
      <CSSTransition
        in={isOpen}
        unmountOnExit
        mountOnEnter
        classNames={ctSlideLeftRight}
        addEndListener={(node, listener) =>
          node.addEventListener("transitionend", listener)
        }
      >
        <PageLayout.Menu className={styles.wrapper}>
          <div ref={menuRef} className={styles.background}>
            <SidebarMenu>
              {children}
              <SidebarMenu.Section heading={t("heading")}>
                {before}

                <SidebarMenu.Button>{t("open_webinars")}</SidebarMenu.Button>

                {isLoggedIn && (
                  <>
                    <NotificationsLoader Consumer={NotificationsMobile} />
                    <SidebarMenu.Button as={MenuLink} to={Paths.ACCOUNT}>
                      {t("account_settings")}
                    </SidebarMenu.Button>
                  </>
                )}

                <DefaultMenuLayoutThemeToggle />

                <WithCloseDrawerMenu>
                  <SidebarMenu.Button
                    onClick={() => {
                      void NiceModal.show(SubmitBidModal, {});
                    }}
                  >
                    {t("submit_bid")}
                  </SidebarMenu.Button>
                </WithCloseDrawerMenu>

                {after}

                {isLoggedIn && (
                  <WithCloseDrawerMenu>
                    <SidebarMenu.Button onClick={handleLogout}>
                      {t("logout")}
                    </SidebarMenu.Button>
                  </WithCloseDrawerMenu>
                )}
              </SidebarMenu.Section>
            </SidebarMenu>
          </div>
        </PageLayout.Menu>
      </CSSTransition>
    </CloseDrawerMenuProvider>
  );
}
