export function getMonthFirstDay(date: Date) {
  const firstDay = new Date(date);
  firstDay.setDate(1);
  return firstDay;
}

export function getMonthLastDay(date: Date) {
  const lastDay = new Date(date);
  lastDay.setDate(1);
  lastDay.setMonth(lastDay.getMonth() + 1);
  lastDay.setDate(0);
  return lastDay;
}

export function getNormalizedDay(date: Date) {
  return (date.getDay() + 6) % 7;
}

export function getMonthWeeksCount(date: Date) {
  const first = getMonthFirstDay(date);
  const last = getMonthLastDay(date);

  const days = last.getDate();
  const day = getNormalizedDay(first);

  if (days === 28 && day === 0) {
    return 4;
  }

  if (day > 7 - (days - 28)) {
    return 6;
  }

  return 5;
}

export function getMonday(date: Date) {
  const monday = new Date(date);
  monday.setDate(monday.getDate() - getNormalizedDay(monday));
  return monday;
}

export function getSunday(date: Date) {
  const sunday = new Date(date);
  sunday.setDate(sunday.getDate() + (6 - getNormalizedDay(sunday)));
  return sunday;
}

export function today() {
  const day = new Date();
  day.setHours(0, 0, 0, 0);
  return day;
}

export function truncateTime(date: Date) {
  const copy = new Date(date);
  copy.setHours(0, 0, 0, 0);
  return copy;
}

export function getWeekdays(locales?: string[] | string) {
  const date = getMonday(today());

  const formatter = new Intl.DateTimeFormat(locales, { weekday: "short" });

  return Array.from({ length: 7 }, (_, wd) => {
    const day = new Date(date);
    day.setDate(day.getDate() + wd);

    return formatter.format(day);
  });
}

/**
 * @returns month and year of the specified date in format `MMMM YYYY`
 */
export function getMonthAndYear(date: Date, locales?: Intl.LocalesArgument) {
  const month = date.toLocaleDateString(locales, {
    month: "long",
  });

  return `${month} ${date.getFullYear()}`;
}

export function isSameYear(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear();
}

export function isSameMonth(date1: Date, date2: Date) {
  return date1.getMonth() === date2.getMonth() && isSameYear(date1, date2);
}

export function isSameDay(date1: Date, date2: Date) {
  return date1.getDate() === date2.getDate() && isSameMonth(date1, date2);
}

export function toPeriod(date1: Date, date2: Date): [start: Date, end: Date] {
  if (date1 < date2) {
    return [date1, date2];
  }

  return [date2, date1];
}

export function tomorrow(date: Date) {
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
}

export function yesterday(date: Date) {
  const prevDay = new Date(date);
  prevDay.setDate(prevDay.getDate() - 1);
  return prevDay;
}
