import { type ReactNode } from "react";

import { useModal } from "@ebay/nice-modal-react";
import { cx } from "classix";
import { CSSTransition } from "react-transition-group";

import { scale as ctScale } from "@rls/styles/transitions.css";

import { useLockScroll } from "@/hooks/dom/useLockScroll";
import useCloseWatcher from "@/hooks/useCloseWatcher";
import { addTransitionEndListener } from "@/utils/transition";

import ModalAnimation from "../../components/animation/ModalAnimation";
import { useCloseModal } from "../../hooks";

import * as styles from "./ModalLayoutBase.css";

export interface ModalLayoutBaseProps {
  modalClassName?: string;
  children: ReactNode;
}

export default function ModalLayoutBase({
  children,
  modalClassName,
}: ModalLayoutBaseProps) {
  const { visible } = useModal();
  const close = useCloseModal();

  useCloseWatcher({
    onClose: close,
    disabled: !visible,
  });

  useLockScroll();

  return (
    <ModalAnimation>
      <div className={cx(styles.modal, modalClassName)}>
        <div className={styles.background} onClick={() => close()} />
        <CSSTransition
          in={visible}
          classNames={ctScale}
          timeout={300}
          addEndListener={addTransitionEndListener}
          appear
          unmountOnExit
          mountOnEnter
        >
          <div className={styles.content}>{children}</div>
        </CSSTransition>
      </div>
    </ModalAnimation>
  );
}
