import { createMutation } from "react-query-kit";

import { kyFetcher } from "@/api/fetcher";
import {
  conversationCategoriesResponse,
  conversationsResponseSchema,
  conversationStatusesResponse,
} from "@/schemas/course/conversation";
import { successfulResponse } from "@/schemas/response";
import isNullish from "@/utils/checks/isNullish";
import {
  createInfiniteQueryWithSchema,
  createQueryWithSchema,
} from "@/utils/fetch/createQueryFunction";
import filterSearchParams from "@/utils/query/filterSearchParams";

export interface ConversationRequestParams {
  course: string;
  cohort?: number;
}

export interface ConversationTableRequestParams
  extends ConversationRequestParams {
  statuses?: string[] | null | undefined;
  categories?: string[] | null | undefined;
  sort?: string | null | undefined;
  order?: string | null | undefined;
  before?: string | null | undefined;
  after?: string | null | undefined;
  search?: string | null | undefined;
}

export const useCourseConversationQuery = createInfiniteQueryWithSchema(
  successfulResponse(conversationsResponseSchema)
)<ConversationTableRequestParams>({
  primaryKey: "conversation/conversations",
  queryFn: async ({
    pageParam = 1,
    queryKey: [
      ,
      {
        course,
        cohort,
        statuses,
        categories,
        sort,
        order,
        search,
        before,
        after,
      },
    ],
    signal,
  }) => {
    const params = new URLSearchParams();

    statuses?.forEach((status) => params.append("statuses[]", status));
    categories?.forEach((category) => params.append("categories[]", category));

    if (sort && order) {
      params.set("_sort", sort);
      params.set("_order", order);
    }

    if (search) {
      params.set("q", search);
    }

    if (before && after) {
      params.set("before", before);
      params.set("after", after);
    }

    if (!isNullish(cohort)) {
      params.set("cohort", String(cohort));
    }

    params.set("page", String(pageParam));

    return await kyFetcher
      .get(`courses/${course}/discussions`, {
        signal,
        searchParams: params,
      })
      .json();
  },
  getNextPageParam: ({ nextPage }) => {
    return nextPage ?? undefined;
  },
});

export const useConversationCategories = createQueryWithSchema(
  successfulResponse(conversationCategoriesResponse)
)({
  primaryKey: "conversation/categories",
  queryFn: async ({ signal }) => {
    return await kyFetcher.get("discussions/categories", { signal }).json();
  },
});

export const useConversationStatuses = createQueryWithSchema(
  successfulResponse(conversationStatusesResponse)
)({
  primaryKey: "conversation/statuses",
  queryFn: async ({ signal }) => {
    return await kyFetcher.get("discussions/statuses", { signal }).json();
  },
});

export const useConversationReadAllMutation = createMutation({
  mutationFn: async ({ course, cohort }: ConversationRequestParams) => {
    return await kyFetcher
      .put(`courses/${course}/discussions/read-all`, {
        searchParams: filterSearchParams({
          cohort,
        }),
      })
      .json();
  },
});

export const useConversationMarkReadMutation = createMutation({
  mutationFn: async ({ id }: { id: number }) => {
    return await kyFetcher.put(`discussions/${id}/read/`).json();
  },
});
