import { useCallback } from "react";

import useAuthStore from "@/stores/auth";
import useRedirectUrlStore from "@/stores/redirect-url";

export default function useLogout() {
  const logout = useAuthStore((state) => state.logout);
  const clearRedirect = useRedirectUrlStore((state) => state.clear);

  return useCallback(() => {
    logout();
    clearRedirect();
  }, [clearRedirect, logout]);
}
