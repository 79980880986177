import type { ElementType, PropsWithChildren } from "react";

import type { PolymorphicPropsWithoutRef } from "@kosmotema/react-polymorphic-types";
import { cx } from "classix";

import { Text } from "@rls/ui-kit";

import type { PropsWithClassName } from "@/utils/props";

import SidebarMenuLocked from "../locked/SidebarMenuLocked";

import { button } from "./SidebarMenuButton.css";

export const SidebarMenuButtonDefaultElement = "button";

export type SidebarMenuButtonOwnProps = PropsWithChildren &
  PropsWithClassName & {
    disabled?: boolean;
    notReady?: boolean;
  };

export type SidebarMenuButtonProps<
  TElement extends ElementType = typeof SidebarMenuButtonDefaultElement
> = PolymorphicPropsWithoutRef<SidebarMenuButtonOwnProps, TElement>;

export default function SidebarMenuButton<
  T extends ElementType = typeof SidebarMenuButtonDefaultElement
>({ as, className, disabled, notReady, ...rest }: SidebarMenuButtonProps<T>) {
  if (notReady) {
    const { children } = rest;

    return (
      <Text variant="navigation-primary" color="navigation-additional">
        {children}
      </Text>
    );
  }

  if (disabled) {
    return <SidebarMenuLocked>{rest.children}</SidebarMenuLocked>;
  }

  const Element: ElementType = as ?? SidebarMenuButtonDefaultElement;
  return (
    <Text
      as={Element}
      variant="navigation-primary"
      color="custom"
      className={cx(button, className)}
      {...rest}
    />
  );
}
