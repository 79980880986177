import { useCallback } from "react";

import { useModal } from "@ebay/nice-modal-react";

export function useRejectModal(): () => void;
export function useRejectModal<T>(): (value?: T) => void;

export function useRejectModal() {
  const { reject, hide } = useModal();

  return useCallback(
    (reason?: unknown) => {
      reject(reason);
      void hide();
    },
    [hide, reject]
  );
}

export function useCloseModal() {
  return useResolveModal();
}

export function useResolveModal(): () => void;
export function useResolveModal<T>(): (value: T) => void;

export function useResolveModal() {
  const { resolve, hide } = useModal();

  return useCallback(
    (value?: unknown) => {
      resolve(value);
      void hide();
    },
    [hide, resolve]
  );
}
