import { cx } from "classix";

import CheckboxIcon from "@rls/icons/checkbox";

import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./ToggleCheckboxIcon.css";

export interface ToggleCheckboxIconProps extends PropsWithClassName {
  as?: typeof CheckboxIcon;
  checked?: boolean;
}

/**
 * NOTE: Possible icon placement:
 *
 * @example
 *
 * <input type="checkbox" />
 * <ToggleCheckboxIcon />
 *
 * @example
 *
 * <input type="checkbox" />
 * <label>
 *   <ToggleCheckboxIcon />
 * </
 *
 */
export default function ToggleCheckboxIcon({
  as: Icon = CheckboxIcon,
  className,
  checked,
}: ToggleCheckboxIconProps) {
  return <Icon className={cx(styles.checkbox({ checked }), className)} />;
}
