import { z as zod } from "zod";

import { zodDate } from "./helpers";

const notificationSchema = zod.object({
  id: zod.number(),
  title: zod.string(),
  is_new: zod.boolean(),
  updated_at: zodDate,
  category: zod.string(),
  course: zod.object({
    name: zod.string(),
    url: zod.string(),
  }),
  cohort: zod.object({
    id: zod.number(),
    name: zod.string(),
  }),
  thread_link: zod.string().nullable(),
});

export type Notification = zod.infer<typeof notificationSchema>;

export const notificationsResponseSchema = zod.object({
  notifications: zod.array(notificationSchema),
  new_count: zod.number(),
});

export type NotificationsResponseSchema = zod.infer<
  typeof notificationsResponseSchema
>;
