import queryClient from "@/clients/query";
import TranslationNamespace from "@/constants/translation/namespace";
import { useAccountCoursesQuery } from "@/queries/account";
import { preloadFromQueryKit, preloadTranslation } from "@/utils/preload";

export default async function loader() {
  await preloadTranslation(TranslationNamespace.SIDEBAR_MENU);

  return preloadFromQueryKit(queryClient, useAccountCoursesQuery, {});
}
