import { Link } from "react-router-dom";

import RebrainLogo from "@rls/icons/logo";
import { Text } from "@rls/ui-kit";

import useMenuState from "@/context/menu-state/useMenuState";
import useMainPagePath from "@/hooks/useMainPagePath";

import * as styles from "./HeaderLogo.css";

export default function HeaderLogo() {
  const mainPage = useMainPagePath();
  const { change } = useMenuState();

  return (
    <Link className={styles.link} to={mainPage} onClick={() => change(false)}>
      <RebrainLogo className={styles.logo} />
      <Text className={styles.text} variant="labels" color="custom">
        BETA
      </Text>
    </Link>
  );
}
