import { Outlet } from "react-router-dom";

import Help from "../Help";

export default function HelpLayout() {
  return (
    <>
      <Outlet />
      <Help />
    </>
  );
}
