import { z as zod } from "zod";

import { zodDate } from "@/schemas/helpers";
import { videoSpeakerSchema } from "@/schemas/video";

export const liveClassListItemSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  performance_date: zodDate,
  speakers: zod.array(videoSpeakerSchema),
  video: zod.number().nullish(),
});

export type LiveClassListItem = zod.infer<typeof liveClassListItemSchema>;

export const liveClassesListSchema = zod.array(liveClassListItemSchema);

export const allLiveClassesPageSchema = zod.object({
  webinars: liveClassesListSchema,
  nextPage: zod.number().nullable(),
});
