import { useEffect } from "react";

import { Navigate } from "react-router-dom";

import Paths from "@/constants/paths";
import useRedirectUrlStore from "@/stores/redirect-url";

export default function RestoreRedirectUrl() {
  const url = useRedirectUrlStore((state) => state.url) ?? Paths.COURSES;

  useEffect(
    () => () => {
      useRedirectUrlStore.getState().clear();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <Navigate to={url} />;
}
