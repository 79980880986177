import type { AfterResponseHook } from "ky";

import useAuthStore from "@/stores/auth";

export const kyHandleUnauthorized: AfterResponseHook = (
  _request,
  _options,
  response
) => {
  if (response.status === 401) {
    useAuthStore.getState().logout();
  }
};
