import type { ComponentType } from "react";

import ReCaptchaWrapper from "./ReCaptchaWrapper";

export default function withReCaptchaWrapper<TProps extends object>(
  Component: ComponentType<TProps>
) {
  // eslint-disable-next-line react/function-component-definition
  return function WithReCaptchaWrapper(props: TProps) {
    return (
      <ReCaptchaWrapper>
        <Component {...props} />
      </ReCaptchaWrapper>
    );
  };
}
