import { redirect, type LoaderFunctionArgs } from "react-router-dom";

import queryClient from "@/clients/query";
import Paths from "@/constants/paths";
import { useNotificationsQuery } from "@/queries/notifications";
import useAuthStore from "@/stores/auth";
import useRedirectUrlStore from "@/stores/redirect-url";
import { preloadFromQueryKit } from "@/utils/preload";
import { unauthorizedRedirect } from "@/utils/router/redirect";
import { urlWithCurrentSearch } from "@/utils/url";

export default function loader(guard: "auth" | "un-auth") {
  return ({ request: { url } }: LoaderFunctionArgs) => {
    const isLoggedIn = useAuthStore.getState().isLoggedIn();

    if (guard === "auth" && !isLoggedIn) {
      throw unauthorizedRedirect(Paths.LOGIN, url);
    }

    if (guard === "un-auth" && isLoggedIn) {
      throw redirect(
        useRedirectUrlStore.getState().pop() ??
          urlWithCurrentSearch(Paths.COURSES, url)
      );
    }

    if (isLoggedIn) {
      void preloadFromQueryKit(queryClient, useNotificationsQuery, {}).catch(
        () => {}
      );
    }

    return {
      guard,
    };
  };
}
