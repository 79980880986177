import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Colorize } from "@rls/ui-kit";

import MobileWindow from "@/components/mobile-window/MobileWindow";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenuButton from "@/features/sidebar-menu-layout/components/menu/button/SidebarMenuButton";
import type { Notification } from "@/schemas/notifications";

import NotificationsBottomButtons from "../bottom-buttons/NotificationsBottomButtons";
import NotificationsEmpty from "../empty/NotificationsEmpty";
import NotificationsEntry from "../entry/NotificationsEntry";

import * as styles from "./NotificationsMobile.css";

export interface NotificationsMobileProps {
  notifications: Notification[];
  count: number;
}

export default function NotificationsMobile({
  notifications,
  count,
}: NotificationsMobileProps) {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_NOTIFICATIONS,
  });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SidebarMenuButton onClick={() => setIsOpen(true)}>
        {t("label")}
        {count > 0 ? (
          <>
            : <Colorize color="aqua:light">{count}</Colorize>
          </>
        ) : null}
      </SidebarMenuButton>
      <MobileWindow isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {notifications.length > 0 ? (
          <div className={styles.content}>
            <ul className={styles.items}>
              {notifications.map((notification) => (
                <NotificationsEntry key={notification.id} {...notification} />
              ))}
            </ul>

            <NotificationsBottomButtons />
          </div>
        ) : (
          <NotificationsEmpty className={styles.empty} />
        )}
      </MobileWindow>
    </>
  );
}
