import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { useCourseConversationQuery } from "@/queries/course/conversation";
import { useNotificationsQuery } from "@/queries/notifications";
import type { QueryKitData } from "@/types/query/data";

// TODO: extract common logic with conversation's `useOnReadAll` hook
export default function useOnReadAll() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.setQueriesData<QueryKitData<typeof useCourseConversationQuery>>(
      {
        queryKey: useCourseConversationQuery.getKey(),
      },
      (data) => {
        return produce(data, (draft) => {
          draft?.pages.forEach((page) =>
            page.discussions.forEach((discussion) => {
              discussion.is_new = false;
            })
          );
        });
      }
    );

    queryClient.setQueriesData<QueryKitData<typeof useNotificationsQuery>>(
      {
        queryKey: useNotificationsQuery.getKey(),
      },
      (data) => {
        if (!data) {
          return data;
        }

        return produce(data, (draft) => {
          draft.notifications.forEach((notification) => {
            notification.is_new = false;
          });
          draft.new_count = 0;
        });
      }
    );
  }, [queryClient]);
}
