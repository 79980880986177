import { cx } from "classix";
import { match } from "ts-pattern";

import DoneIcon from "@rls/icons/checkmark";
import InProgressIcon from "@rls/icons/clock";

import type { CourseTaskStatus } from "@/schemas/course/menu/task";
import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./TaskStatusIcon.css";

export interface TaskStatusIconProps extends PropsWithClassName {
  status: CourseTaskStatus | null | undefined;
  preserveSpace?: boolean;
}

export default function TaskStatusIcon({
  className,
  status,
  preserveSpace = false,
}: TaskStatusIconProps) {
  return match(status)
    .with("done", () => (
      <span className={cx(styles.wrapper, className)}>
        <DoneIcon className={cx(styles.icon({ variant: "done" }))} />
      </span>
    ))
    .with("in-progress", () => (
      <span className={cx(styles.wrapper, className)}>
        <InProgressIcon
          className={cx(styles.icon({ variant: "in-progress" }))}
        />
      </span>
    ))
    .otherwise(() =>
      preserveSpace ? <span className={cx(styles.wrapper, className)} /> : null
    );
}
