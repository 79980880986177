// import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export async function getVisitorId() {
  // const apiKey = import.meta.env.APP_FP_API_KEY;

  // if (!apiKey) {
  //   // console.warn("missed fingerprint api key");
  //   return null;
  // }

  // try {
  //   const fp = await FingerprintJS.load({
  //     apiKey: apiKey,
  //     region: "eu",
  //     endpoint: import.meta.env.APP_FP_ENDPOINT,
  //   });

  //   const result = await fp.get();
  //   return result.visitorId;
  // } catch {
  return null;
  // }
}
