import type { ReactNode } from "react";

import { useModal } from "@ebay/nice-modal-react";
import { CSSTransition } from "react-transition-group";
import type { CSSTransitionProps } from "react-transition-group/CSSTransition";

import { appear as ctAppear } from "@rls/styles/transitions.css";

export interface ModalAnimationProps {
  classNames?: CSSTransitionProps["classNames"];
  children: ReactNode;
}

export default function ModalAnimation({
  classNames,
  children,
}: ModalAnimationProps) {
  const { visible, remove } = useModal();

  return (
    <CSSTransition
      addEndListener={(node, listener) => {
        const duration = Number.parseFloat(
          window.getComputedStyle(node).transitionDuration
        );
        if (duration) {
          node.addEventListener("transitionend", listener);
        } else {
          listener();
        }
      }}
      timeout={300} // fallback
      onExited={() => {
        remove();
      }}
      classNames={classNames ?? ctAppear}
      in={visible}
      appear
      unmountOnExit
      mountOnEnter
    >
      {children}
    </CSSTransition>
  );
}
