import {
  createContext,
  useMemo,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from "react";

import noop from "@/utils/noop";

import * as styles from "./CohortSelectorStateContext.css";

export interface ICohortSelectorStateContext {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const CohortSelectorStateContext =
  createContext<ICohortSelectorStateContext>({
    isOpen: false,
    setOpen: noop,
  });

export function CohortSelectorStateProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);

  const value = useMemo<ICohortSelectorStateContext>(
    () => ({
      isOpen,
      setOpen: setIsOpen,
    }),
    [isOpen]
  );

  return (
    <CohortSelectorStateContext.Provider value={value}>
      <div className={styles.wrapper({ open: isOpen })}>{children}</div>
    </CohortSelectorStateContext.Provider>
  );
}
