import { useTranslation } from "react-i18next";

import { ButtonArrows, Text } from "@rls/ui-kit";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";

import NotificationsReadAll from "../read-all/NotificationsReadAll";

import * as styles from "./NotificationsBottomButtons.css";

export interface NotificationsBottomButtonsProps {
  chatUrl?: string;
}

export default function NotificationsBottomButtons({
  chatUrl,
}: NotificationsBottomButtonsProps) {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_NOTIFICATIONS,
  });

  return (
    <div className={styles.root({ hasChatLink: !!chatUrl })}>
      {chatUrl ? (
        <Text
          as="a"
          variant="buttons"
          color="custom"
          className={styles.primaryButton}
        >
          <ButtonArrows>{t("to_chat")}</ButtonArrows>
        </Text>
      ) : null}
      <NotificationsReadAll />
    </div>
  );
}
