import { z as zod } from "zod";

import { zodDate } from "./helpers";

export const videoSpeakerSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  photo: zod.string().nullish(),
  description: zod.string().nullish(),
  position: zod.string().nullish(),
});

export const videoLayoutPlaylistSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  available: zod.boolean(),
});

export const videoLayoutCourseSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  url: zod.string(),
  playlists: zod.array(videoLayoutPlaylistSchema),
});

export const videoSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  preview_image: zod.string().nullish(),
  url: zod.string().nullish(),
  description: zod.string().nullish(),
  recorded_at: zodDate,
  speakers: zod.array(videoSpeakerSchema),
  views_count: zod.number(),
  courses: zod.array(videoLayoutCourseSchema),
  favorite: zod.boolean().nullish(),
});

export type Video = zod.infer<typeof videoSchema>;
export type VideoSpeaker = zod.infer<typeof videoSpeakerSchema>;
export type VideoLayoutPlaylist = zod.infer<typeof videoLayoutPlaylistSchema>;
export type VideoLayoutCourse = zod.infer<typeof videoLayoutCourseSchema>;
