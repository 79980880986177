import { createContext, useMemo, type PropsWithChildren } from "react";

import type {
  ExtendedCourseMenuTask,
  ExtendedCourseMenuTaskModule,
} from "@/utils/task-modules/transform";
import { isModule } from "@/utils/task-modules/type";

import useCourseInfo from "../course-info/useCourseInfo";

export interface CourseTaskLookupEntry {
  module: ExtendedCourseMenuTaskModule;
  task: ExtendedCourseMenuTask;
}

export type ICourseTaskLookupContext = ReadonlyMap<
  number,
  CourseTaskLookupEntry
>;

export const CourseTaskLookupContext = createContext<ICourseTaskLookupContext>(
  new Map()
);

export function CourseTaskLookupProvider({ children }: PropsWithChildren) {
  const { modules } = useCourseInfo();

  const lookup = useMemo<ICourseTaskLookupContext>(() => {
    const internalLookup = new Map<number, CourseTaskLookupEntry>();

    if (modules.length === 0) {
      return internalLookup;
    }

    const handleModule = (module: ExtendedCourseMenuTaskModule) => {
      module.tasks.forEach((task) => {
        if (isModule(task)) {
          handleModule(task);
        } else {
          internalLookup.set(task.id, {
            module,
            task,
          });
        }
      });
    };

    modules.forEach((module) => {
      handleModule(module);
    });

    return internalLookup;
  }, [modules]);

  return (
    <CourseTaskLookupContext.Provider value={lookup}>
      {children}
    </CourseTaskLookupContext.Provider>
  );
}
