import { useMemo, useSyncExternalStore } from "react";

export default function useMatchMedia(
  query: string,
  /**
   * Will be used during SSR (or when `typeof window === undefined`)
   */
  fallback?: boolean
) {
  const getServerSnapshot = useMemo(
    () => (fallback === undefined ? undefined : () => fallback),
    [fallback]
  );

  const [getSnapshot, subscribe] = useMemo(() => {
    const mediaQueryList = window.matchMedia(query);

    return [
      () => mediaQueryList.matches,
      (notify: () => void) => {
        if ("addEventListener" in mediaQueryList) {
          mediaQueryList.addEventListener("change", notify);

          return () => mediaQueryList.removeEventListener("change", notify);
        } else {
          // fuck safari
          (mediaQueryList as MediaQueryList).addListener(notify);

          // fuck safari
          return () =>
            (mediaQueryList as MediaQueryList).removeListener(notify);
        }
      },
    ];
  }, [query]);

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
