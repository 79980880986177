import { HTTPError } from "ky";
import { z as zod } from "zod";

export default function parseErrorResponse<T = unknown>(error: unknown) {
  if (error instanceof HTTPError) {
    return error.data as T;
  }

  return undefined;
}

const errorMessageSchema = zod.union([
  zod
    .object({
      error: zod.string(),
    })
    .transform(({ error }) => error),
  zod
    .object({
      message: zod.string(),
    })
    .transform(({ message }) => message),
]);

export function parseErrorMessage<
  T extends string | { error: string } = string
>(error: unknown) {
  const response = parseErrorResponse<T>(error);

  const message = errorMessageSchema.safeParse(response);

  if (message.success) {
    return message.data as T extends { error: string } ? T["error"] : T;
  }

  return undefined;
}

const payloadErrorFieldSchema = zod
  .object({
    payload: zod.object({
      errorField: zod.record(zod.unknown()),
    }),
  })
  .transform(({ payload: { errorField } }) => errorField);

export function parsePayloadErrorField<
  T extends
    | object
    | {
        payload?: { errorField?: object };
      } = object
>(error: unknown) {
  const response = parseErrorResponse<T>(error);

  const errorField = payloadErrorFieldSchema.safeParse(response);

  if (errorField.success) {
    return errorField.data as T extends {
      payload?: { errorField?: object };
    }
      ? NonNullable<NonNullable<T["payload"]>["errorField"]>
      : T;
  }

  return undefined;
}

export function parseErrorStatus(err: unknown) {
  return err instanceof HTTPError ? err.response.status : undefined;
}
