import { Suspense, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { useRouteError } from "react-router-dom";
import { match } from "ts-pattern";

import isNetworkError from "@/utils/error/isNetworkError";
import isNotFoundError from "@/utils/error/isNotFound";

import Error404 from "../404/Error404";
import NetworkError from "../network/NetworkError";
import UnexpectedError from "../unexpected/UnexpectedError";

export default function ErrorResolver() {
  const error = useRouteError();

  useEffect(() => {
    if (isNotFoundError(error)) {
      return;
    }

    Sentry.captureException(error);
  }, [error]);

  return (
    <Suspense>
      {match(error)
        .when(
          (err) => isNotFoundError(err),
          () => <Error404 />
        )
        .when(
          (err) => isNetworkError(err),
          () => <NetworkError />
        )
        .otherwise(() => (
          <UnexpectedError />
        ))}
    </Suspense>
  );
}
