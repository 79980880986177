import { match } from "ts-pattern";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type VideosDisplayType = "grid" | "list";

export interface VideosDisplayState {
  type: VideosDisplayType;

  change: (newType: VideosDisplayType) => void;
  toggle: () => void;
  reset: () => void;
}

const initialState: Pick<VideosDisplayState, "type"> = {
  type: "grid",
};

export const useVideosDisplayStore = create<VideosDisplayState>()(
  persist(
    (set, get) => ({
      ...initialState,

      change: (newType) => set({ type: newType }),
      toggle: () =>
        set({
          type: match(get().type)
            .with("grid", () => "list" as const)
            .with("list", () => "grid" as const)
            .exhaustive(),
        }),
      reset: () => set(initialState),
    }),
    {
      name: "videos-display-type-store",
    }
  )
);
