import isNullish from "@/utils/checks/isNullish";

export default function filterSearchParams<
  T extends Record<string, number | string | null | undefined>
>(params: T) {
  const filtered = Object.entries(params).filter(
    (entry): entry is [string, number | string] => {
      const [, value] = entry;
      return !isNullish(value) && value !== "";
    }
  );

  if (filtered.length === 0) {
    return undefined;
  }

  return Object.fromEntries(filtered) as Record<
    keyof T | (string & {}),
    number | string
  >;
}
