import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Headline } from "@rls/ui-kit";

import ErrorImg from "@/assets/images/errors/404.svg?component";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useMainPagePath from "@/hooks/useMainPagePath";
import ErrorLayout from "@/layouts/error/ErrorLayout";

import * as styles from "./Error404.css";

export default function Error404() {
  const { t } = useTranslation(TranslationNamespace.ERROR, {
    keyPrefix: TranslationKeyPrefix.ERROR_404,
  });

  const mainPagePath = useMainPagePath();

  return (
    <ErrorLayout pageTitle="404">
      <ErrorImg className={styles.image} />
      <Headline as="p" variant="h3" color="headlines" className={styles.title}>
        <Trans
          t={t}
          i18nKey="text"
          components={{
            "main-page": <Link className={styles.link} to={mainPagePath} />,
          }}
        />
      </Headline>
    </ErrorLayout>
  );
}
