import SidebarMenuCollapseContent, {
  type SidebarMenuCollapseContentProps,
} from "./content/SidebarMenuCollapseContent";

import SidebarMenuLocked from "../locked/SidebarMenuLocked";

export interface SidebarMenuCollapseProps
  extends SidebarMenuCollapseContentProps {
  disabled?: boolean;
}

export default function SidebarMenuCollapse({
  disabled,
  text,
  ...rest
}: SidebarMenuCollapseProps) {
  if (disabled) {
    return <SidebarMenuLocked>{text}</SidebarMenuLocked>;
  }

  return <SidebarMenuCollapseContent {...rest} text={text} />;
}
