import AccountMenuCoursesBlock, {
  type AccountMenuCoursesBlockProps,
} from "../blocks/courses/AccountMenuCoursesBlock";
import AccountMenuSectionsBlock from "../blocks/sections/AccountMenuSectionsBlock";

export interface AccountMenuSharedProps {
  courses: AccountMenuCoursesBlockProps["courses"];
}

export default function AccountMenuShared({ courses }: AccountMenuSharedProps) {
  return (
    <>
      <AccountMenuSectionsBlock />
      <AccountMenuCoursesBlock courses={courses} />
    </>
  );
}
