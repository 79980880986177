import type { RefObject } from "react";

export function addTransitionEndListener(
  node: HTMLElement,
  listener: () => void
) {
  node.addEventListener("transitionend", listener);
}

export function createAddTransitionEndListener(
  node: RefObject<HTMLElement>
): (listener: () => void) => void {
  return (listener) =>
    node.current ? addTransitionEndListener(node.current, listener) : undefined;
}
