export function formatBaseProgramComponent(index: number) {
  return formatDeepProgramComponent(index).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
}

export function formatDeepProgramComponent(index: number) {
  return index + 1;
}

export function formatProgramComponent(
  index: number,
  base: string | undefined
) {
  return base
    ? `${base}.${formatDeepProgramComponent(index)}`
    : formatBaseProgramComponent(index);
}
