import type { ComponentType } from "react";

export type PageComponent = { default: ComponentType };

export default function createLazyRoute(factory: () => Promise<PageComponent>) {
  return async () => {
    const { default: page } = await factory();

    return {
      Component: page,
    };
  };
}
