import { Navigate, Outlet, useLoaderData } from "react-router-dom";

import MainSpinner from "@/components/spinners/main/MainSpinner";
import RestoreRedirectUrl from "@/components/utils/redirect/RestoreRedirectUrl";
import SaveRedirectUrl from "@/components/utils/redirect/SaveRedirectUrl";
import ResetQueryCache from "@/components/utils/reset-query-cache/ResetQueryCache";
import Paths from "@/constants/paths";
import { useIsLoggedIn } from "@/stores/auth";

import type loader from "./loader";

export default function AuthGuardLayout() {
  const isLoggedIn = useIsLoggedIn();

  const { guard } = useLoaderData() as ReturnType<ReturnType<typeof loader>>;

  if (guard === "auth" && !isLoggedIn) {
    return (
      <>
        <SaveRedirectUrl />
        <ResetQueryCache />
        <Navigate to={Paths.LOGIN} />
        <MainSpinner />
      </>
    );
  }

  if (guard === "un-auth" && isLoggedIn) {
    return (
      <>
        <RestoreRedirectUrl />
        <MainSpinner />
      </>
    );
  }

  return <Outlet />;
}
