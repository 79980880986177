import type { PropsWithChildren } from "react";

import ClosedIcon from "@rls/icons/lock";
import { Text } from "@rls/ui-kit";

import * as styles from "./SidebarMenuLocked.css";

export interface SidebarMenuLockedProps extends PropsWithChildren {
  type?: "primary" | "secondary";
}

export default function SidebarMenuLocked({
  type = "primary",
  children,
}: SidebarMenuLockedProps) {
  return (
    <Text
      variant={`navigation-${type}`}
      className={styles.button}
      color="custom"
    >
      <span>{children}</span>
      <ClosedIcon className={styles.icon} />
    </Text>
  );
}
