enum Paths {
  LOGIN = "/",
  REGISTRATION = "/registration",
  COURSES = "/courses",

  ACCOUNT = "/account",
  ACCOUNT_PAYMENTS = "/account/payments",
  ACCOUNT_REWARDS = "/account/rewards",
  ACCOUNT_CERTIFICATES = "/account/certificates",

  COURSE = "/course/:course",
  COURSE_ABOUT = "/course/:course/about",
  COURSE_VIDEOS = "/course/:course/videos",
  COURSE_TASK = "/course/:course/task/:task",
  COURSE_SELECT_COHORT = "/course/:course/pay",
  COURSE_PAY = "/course/:course/cohort/:cohort/pay",
  COURSE_TEST = "/course/:course/test",
  COURSE_LIVE_CLASSES = "/course/:course/live-classes",
  COURSE_LIVE_CLASS = "/course/:course/live-class/:liveClass",
  COURSE_CONVERSATION = "/course/:course/conversation",
  COURSE_PARAMETERS = "/course/:course/parameters",

  VERIFY_EMAIL = "/email/verify/:token",

  RESTORE_PASSWORD = "/restore-password",
  CHANGE_PASSWORD = "/change-password/:token",

  VIDEO = "/video/:video",
  VIDEO_SECRET = "/video/secret/:token",

  IMPERSONATE = "/impersonate"
}

export default Paths;
