import { countryPhoneData } from "./data";

type Digit = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

interface CountryCodeLookup
  extends Partial<Record<Digit, CountryCodeLookup | undefined>> {
  parent?: CountryCodeLookup;
  mask?: string;
}

function codeInfoToLookup() {
  const lookup: CountryCodeLookup = {};

  countryPhoneData.forEach((item) => {
    const { prefix, mask } = item;

    const prefixes = Array.isArray(prefix) ? prefix : [prefix];

    prefixes.forEach((prefix) => {
      let node = lookup;

      for (const digit of prefix) {
        node = node[digit as Digit] ??= {
          parent: node,
        };
      }

      node.mask = mask;
    });
  });

  return lookup;
}

const lookup = codeInfoToLookup();

export function trimPhone(value: string) {
  return value.replaceAll(/\D/g, "");
}

/**
 * NOTE: don't forget to trim non-digits chars using {@link trimPhone}
 */
function findLookupNodeWithMaskOrRoot(digitsValue: string) {
  let node = lookup;
  for (const digit of digitsValue) {
    const nextNode = node[digit as Digit];

    if (!nextNode) {
      break;
    }

    node = nextNode;
  }

  while (!node.mask && node.parent) {
    node = node.parent;
  }

  return node;
}

export const defaultPhoneMask = "+#############";

export function guessMask(value: string, defaultMask = defaultPhoneMask) {
  const digitsValue = trimPhone(value);

  if (!digitsValue) {
    return defaultMask;
  }

  return findLookupNodeWithMaskOrRoot(digitsValue).mask ?? defaultMask;
}

export interface ValidatePhoneOptions {
  /**
   * @default 10
   */
  min?: number;

  /**
   * @default [11, 13]
   */
  fallback?: [from: number, to: number];
}

export function validatePhone(
  value: string,
  { min = 10, fallback: [from, to] = [11, 13] }: ValidatePhoneOptions = {}
) {
  const phoneDigits = trimPhone(value);
  const phoneLength = phoneDigits.length;

  if (phoneLength <= min) {
    return false;
  }

  const { mask } = findLookupNodeWithMaskOrRoot(phoneDigits);

  if (!mask) {
    return phoneLength >= from && phoneLength <= to;
  }

  const allowedLength = [...mask].reduce(
    (count, sym) => count + (sym === "#" ? 1 : 0),
    0
  );

  return phoneLength === allowedLength;
}
