import NiceModal from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";
import invariant from "tiny-invariant";

import { colors } from "@rls/styles/vars.css";

import TranslationNamespace from "@/constants/translation/namespace";
import useCourseInfo from "@/features/course-menu/context/course-info/useCourseInfo";
import useCourseSelectedCohortInfo from "@/features/course-menu/context/selected-cohort-info/useCourseSelectedCohortInfo";
import HelpQuestionModal from "@/features/help/modals/question/HelpQuestionModal";

import HelpOption from "../../option/HelpOption";

export interface HelpCourseOptionsProps {
  chat?: string;
}

export default function HelpCourseOptions({ chat }: HelpCourseOptionsProps) {
  const { t } = useTranslation(TranslationNamespace.HELP);

  const cohort = useCourseSelectedCohortInfo();

  invariant(
    cohort,
    "course options should be rendered inside the `CourseMenuLayout`"
  );

  const { name, id } = cohort;

  const { name: course } = useCourseInfo();

  return (
    <>
      <HelpOption
        activeColor={colors.green.light}
        onClick={() => {
          void NiceModal.show(HelpQuestionModal, {
            target: "tutor",
            cohort: id,
          });
        }}
      >
        {t("tutor_question")}
      </HelpOption>
      <HelpOption
        activeColor={colors.yellow.light}
        onClick={() => {
          void NiceModal.show(HelpQuestionModal, {
            target: "coordinator",
            cohort: id,
          });
        }}
      >
        {t("coordinator_question")}
      </HelpOption>
      {!!chat && (
        <HelpOption as="a" href={chat} target="_blank" rel="noreferrer">
          {t("cohort_chat", { name: `${course} ${name}` })}
        </HelpOption>
      )}
    </>
  );
}
