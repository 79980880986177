import React from "react";

import * as ReactQuery from "@tanstack/react-query";
import ReactDOM from "react-dom";
import * as ReactHookForm from "react-hook-form";
import * as ReactRouterDOM from "react-router-dom";
import * as ReactToastify from "react-toastify";

import { preload as preloadEditor } from "@rls/scoped-markdown-components/editor";
import { preload as preloadViewer } from "@rls/scoped-markdown-components/viewer";
import * as VideoPlayer from "@rls/video-player";

import "@rls/scoped-markdown-components/register";

import { appLanguage } from "./i18n";

setTimeout(() => {
  void preloadViewer();
  void preloadEditor(appLanguage);
});

declare global {
  interface RebrainShared {
    VideoPlayer: typeof VideoPlayer;
  }

  interface Window {
    React: typeof React;
    ReactDOM: typeof ReactDOM;
    ReactHookForm: typeof ReactHookForm;
    ReactQuery: typeof ReactQuery;
    ReactRouterDOM: typeof ReactRouterDOM;
    ReactToastify: typeof ReactToastify;

    RebrainShared: RebrainShared;

    ym?: (id: number | string, action: string, label: string) => void;
  }
}

window.React = React;
window.ReactDOM = ReactDOM;
window.ReactHookForm = ReactHookForm;
window.ReactQuery = ReactQuery;
window.ReactRouterDOM = ReactRouterDOM;
window.ReactToastify = ReactToastify;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
window.RebrainShared ??= {};

window.RebrainShared.VideoPlayer = VideoPlayer;
