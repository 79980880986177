import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useLayoutEffect } from "react";

import PageTransitionProgress from "@/components/navigation/page-transition-progress/PageTransitionProgress";

import { useSearchUtmParams } from "@/hooks/useSearchUtmParams";

// import ToastClose from "@/components/toast/close/ToastClose";

export default function AppLayout() {
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    useSearchUtmParams(searchParams, setSearchParams);
  }, [searchParams, setSearchParams]);

  return (
    <>
      <PageTransitionProgress />
      {/* <CookieThemeSynchronizer /> */}
      <ScrollRestoration />
      <Outlet />
      <ToastContainer
        autoClose={2500}
        position="bottom-right"
        theme="colored"
        icon={false}
        closeButton={false}
        // closeButton={ToastClose}
        style={{
          zIndex: 999_999,
        }}
        hideProgressBar
        newestOnTop
      />
    </>
  );
}
