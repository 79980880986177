export type NumericString = `${number}`;

export interface CountryCodeInfo {
  code: string;
  mask?: string;
  prefix: NumericString | NumericString[];
}

// https://www.itu.int/oth/T0202.aspx?lang=en&parent=T0202
export const countryPhoneData = [
  {
    code: "ua",
    mask: "+### ## ### ## ##",
    prefix: "380",
  },
  {
    code: "by",
    mask: "+### ## #######",
    prefix: "375",
  },
  {
    code: "kz",
    mask: "+# ### #######",
    prefix: ["76", "77"],
  },
  {
    code: "uz",
    prefix: "998",
    mask: "+### ## #######",
  },
  {
    code: "am",
    prefix: "374",
    mask: "+### ########",
  },
  {
    code: "az",
    prefix: "994",
    mask: "+### ## ### ## ##",
  },
  {
    code: "kg",
    prefix: "996",
    mask: "+### ## # ### ###",
  },
  {
    code: "ge",
    prefix: "995",
    mask: "+### ### ### ###",
  },
  {
    code: "ge",
    prefix: "99532",
    mask: "+### ## ### ####",
  },
  {
    code: "tj",
    prefix: "992",
    mask: "+### #########",
  },
  {
    code: "tm",
    prefix: "993",
    mask: "+### # ######",
  },
  {
    code: "rs",
    mask: "+### ## ### ####",
    prefix: "381",
  },
  {
    code: "ru",
    mask: "+# ### ### ####",
    prefix: "7",
  },
] satisfies CountryCodeInfo[];
