import type { ComponentPropsWithoutRef } from "react";

import { generatePath } from "react-router-dom";

import Paths from "@/constants/paths";
import useCourseInfo from "@/features/course-menu/context/course-info/useCourseInfo";
import useIsMandatoryTestNotPassed from "@/features/course-menu/hooks/useIsMandatoryTestNotPassed";
import type { SidebarMenuLayoutHeadingProps } from "@/features/sidebar-menu-layout/components/layout/heading/SidebarMenuLayoutHeading";
import { useCourseParam } from "@/hooks/params";
import MenuLink from "@/layouts/menu/default/link/MenuLink";

export type CourseMenuHeadlineTitleProps = ComponentPropsWithoutRef<
  SidebarMenuLayoutHeadingProps["render"]
>;

export default function CourseMenuHeadlineTitle({
  className,
}: CourseMenuHeadlineTitleProps) {
  const course = useCourseParam();
  const isMandatoryTestNotPassed = useIsMandatoryTestNotPassed();

  const { name } = useCourseInfo();

  if (isMandatoryTestNotPassed) {
    return <p className={className({ interactive: false })}>{name}</p>;
  }

  return (
    <MenuLink
      to={generatePath(Paths.COURSE, { course })}
      className={className({ interactive: true })}
    >
      {name}
    </MenuLink>
  );
}
