import type { CourseMenuTask } from "@/schemas/course/menu/task";
import type { CourseMenuTaskModule } from "@/schemas/course/menu/task-module";

import type {
  ExtendedCourseMenuTask,
  ExtendedCourseMenuTaskModule,
} from "./transform";

export function isModule(
  taskOrTopic: CourseMenuTask | CourseMenuTaskModule
): taskOrTopic is CourseMenuTaskModule;

export function isModule(
  taskOrTopic: ExtendedCourseMenuTask | ExtendedCourseMenuTaskModule
): taskOrTopic is ExtendedCourseMenuTaskModule;

export function isModule(
  taskOrTopic: CourseMenuTask | CourseMenuTaskModule
): taskOrTopic is CourseMenuTaskModule {
  return "tasks" in taskOrTopic;
}

export function isTask(
  taskOrTopic: CourseMenuTask | CourseMenuTaskModule
): taskOrTopic is CourseMenuTask;

export function isTask(
  taskOrTopic: ExtendedCourseMenuTask | ExtendedCourseMenuTaskModule
): taskOrTopic is ExtendedCourseMenuTask;

export function isTask(
  taskOrTopic: CourseMenuTask | CourseMenuTaskModule
): taskOrTopic is CourseMenuTask {
  return !isModule(taskOrTopic);
}
