import NiceModal from "@ebay/nice-modal-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import {
  ControlledForm,
  FormActions,
  FormFieldSet,
  FormInputField,
  FormLegend,
  FormSubmit,
  FormTextareaField,
} from "@rls/ui-kit";

import FormCheckboxField from "@/components/form/field/checkbox/FormCheckboxField";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import ModalLayoutSimple from "@/features/modals/layout/simple/ModalLayoutSimple";
import withReCaptchaWrapper from "@/helpers/recaptcha/withReCaptchaWrapper";
import { submitBidSchema } from "@/validation/submit-bid";

import * as styles from "./SubmitBidModal.css";

const SubmitBidModal = withReCaptchaWrapper(
  NiceModal.create(function SubmitBidModal() {
    const { t } = useTranslation(TranslationNamespace.MODAL, {
      keyPrefix: TranslationKeyPrefix.MODAL_SUBMIT_BID,
    });

    const form = useForm({
      mode: "onTouched",
      resolver: zodResolver(submitBidSchema),
    });

    return (
      <ModalLayoutSimple
        wrapperClassName={styles.wrapper}
        contentClassName={styles.content}
      >
        <ControlledForm
          legend={
            <FormLegend>
              <FormLegend.Headline as="h2">{t("title")}</FormLegend.Headline>
            </FormLegend>
          }
          form={form}
          className={styles.form}
          name="submit_bid"
        >
          <FormFieldSet>
            <FormInputField
              name="name"
              type="text"
              autoComplete="text"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder={t("placeholder_name")}
            />

            <FormInputField
              name="email"
              type="email"
              inputMode="email"
              autoComplete="email"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder={t("placeholder_email")}
            />

            <FormInputField
              name="phone"
              type="text"
              autoComplete="text"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder={t("placeholder_phone")}
            />

            <FormTextareaField
              name="comment"
              placeholder={t("placeholder_comment")}
              className={styles.commentInput}
            />

            <FormCheckboxField name="agreement" defaultChecked>
              <Trans
                t={t}
                i18nKey="rules"
                components={{
                  "privacy-policy": (
                    <a
                      href="https://files.rebrainme.com/doc/privacy.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}
                    />
                  ),
                  "user-agreement": (
                    <a
                      href="https://files.rebrainme.com/doc/user_agreement.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}
                    />
                  ),
                }}
              />
            </FormCheckboxField>
          </FormFieldSet>
          <FormActions>
            <FormSubmit>{t("submit")}</FormSubmit>
          </FormActions>
        </ControlledForm>
      </ModalLayoutSimple>
    );
  })
);

export default SubmitBidModal;
