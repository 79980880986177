import * as styles from "./SidebarMenuLayoutHeading.css";

export interface SidebarMenuLayoutHeadingProps {
  className?: string;
  render: (props: { className: typeof styles.heading }) => JSX.Element;
}

export default function SidebarMenuLayoutHeading({
  className,
  render,
}: SidebarMenuLayoutHeadingProps) {
  return (
    <div className={className}>{render({ className: styles.heading })}</div>
  );
}
