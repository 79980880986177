import { cx } from "classix";
import { useTranslation } from "react-i18next";

import NotificationIcon from "@rls/icons/notification";
import { Text } from "@rls/ui-kit";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./NotificationsEmpty.css";

export default function NotificationsEmpty({ className }: PropsWithClassName) {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_NOTIFICATIONS,
  });

  return (
    <div className={cx(styles.root, className)}>
      <NotificationIcon className={styles.icon} />
      <Text as="p" color="navigation-additional">
        {t("empty")}
      </Text>
    </div>
  );
}
