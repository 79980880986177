import { kyFetcher } from "@/api/fetcher";
import { helpWidgetInfoSchema } from "@/schemas/help";
import { createQueryWithSchema } from "@/utils/fetch/createQueryFunction";
import filterSearchParams from "@/utils/query/filterSearchParams";

export const useHelpWidgetInfoQuery = createQueryWithSchema(
  helpWidgetInfoSchema
)<{
  course?: string;
  cohort?: number;
}>({
  primaryKey: "help/widget",
  queryFn: async ({ queryKey: [, { course, cohort }] }) => {
    return await kyFetcher
      .get("support-info", {
        searchParams: filterSearchParams({
          course,
          cohort,
        }),
      })
      .json();
  },
});
