import { useTranslation } from "react-i18next";
import NiceModal from "@ebay/nice-modal-react";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import AskQuestionModal from "@/components/modals/ask-question/AskQuestionModal";
import SidebarMenuButton from "@/features/sidebar-menu-layout/components/menu/button/SidebarMenuButton";
import SidebarMenuSection from "@/features/sidebar-menu-layout/components/menu/section/SidebarMenuSection";

export interface SidebarSectionGeneralProps {
  headless?: boolean;
}

export default function SidebarSectionGeneral({
  headless,
}: SidebarSectionGeneralProps) {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_COMMON,
  });

  const content = '';

  if (headless) {
    return content;
  }

  return (
    <SidebarMenuSection heading={t("heading")}>{content}</SidebarMenuSection>
  );
}
