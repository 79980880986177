import { z as zod } from "zod";

import { zodDate } from "@/schemas/helpers";

const liveClassesEventColorSchema = zod.union([
  zod.literal("white|black"),
  zod.literal("yellow|orange"),
  zod.literal("light-green|green-herbal"),
  zod.literal("green|blue"),
  zod.literal("red|red"),
]);

export type LiveClassesEventColor = zod.infer<
  typeof liveClassesEventColorSchema
>;

const liveClassesCalendarEventSchema = zod.object({
  id: zod.number(),
  color: liveClassesEventColorSchema.nullish(),
  name: zod.string(),
  performance_date: zodDate,
  is_finished: zod.boolean(),
});

export type LiveClassesCalendarEvent = zod.infer<
  typeof liveClassesCalendarEventSchema
>;

export const liveClassesCalendarSchema = zod.array(
  liveClassesCalendarEventSchema
);

export type LiveClassesCalendar = zod.infer<typeof liveClassesCalendarSchema>;
