import { isRouteErrorResponse } from "react-router-dom";

import NotFoundError from "@/errors/not-found/NotFoundError";
import { parseErrorStatus } from "@/utils/fetch/parseErrorResponse";

export default function isNotFoundError(error: unknown) {
  return (
    (isRouteErrorResponse(error) && error.status === 404) ||
    parseErrorStatus(error) === 404 ||
    error instanceof NotFoundError
  );
}
