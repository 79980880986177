import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BorderedButton } from "@rls/ui-kit";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import { useReadAllMutation } from "@/queries/notifications";

import useOnReadAll from "../../hooks/useOnReadAll";

import * as styles from "./NotificationsReadAll.css";

export default function NotificationsReadAll() {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_NOTIFICATIONS,
  });

  const markReadAll = useOnReadAll();

  const { mutate, isLoading } = useReadAllMutation({
    onSuccess: () => {
      markReadAll();
      toast.success(t("on_read_all"));
    },
  });

  return (
    <BorderedButton
      className={styles.secondaryButton}
      loading={isLoading}
      onClick={() => mutate()}
    >
      {t("read_all")}
    </BorderedButton>
  );
}
