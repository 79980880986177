import { Suspense, useMemo } from "react";

import NiceModal from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import {
  FormTranslationContext,
  type IFormTranslationContext,
} from "@rls/ui-kit/context/form-translation";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";

export default function ModalsLayout() {
  const { t } = useTranslation([
    TranslationNamespace.FORM,
    TranslationNamespace.VALIDATION,
  ]);

  const formTranslation = useMemo<IFormTranslationContext>(
    () => ({
      error: ({ code }) => t(code, { ns: TranslationNamespace.VALIDATION }),
      label: ({ field, form }) => t(`${form}.${field}`),
      placeholder: () => t(`${TranslationKeyPrefix.FORM_SERVICE}.placeholder`),
      service: (key) =>
        t(`${TranslationKeyPrefix.FORM_SERVICE}.${key}`, {
          ns: TranslationNamespace.FORM,
        }),
    }),
    [t]
  );

  return (
    <FormTranslationContext.Provider value={formTranslation}>
      <NiceModal.Provider>
        <Suspense>
          <Outlet />
        </Suspense>
      </NiceModal.Provider>
    </FormTranslationContext.Provider>
  );
}
