import type { PropsWithChildren } from "react";

import { Text } from "@rls/ui-kit";

import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./SidebarMenuSection.css";

export interface SidebarMenuSectionProps
  extends PropsWithChildren,
    PropsWithClassName {
  heading: string;
}

export default function SidebarMenuSection({
  heading,
  className,
  children,
}: SidebarMenuSectionProps) {
  return (
    <section className={className}>
      <Text
        as="h4"
        variant="buttons"
        color="navigation-additional"
        className={styles.heading}
      >
        {heading}
      </Text>

      {children && <div className={styles.items}>{children}</div>}
    </section>
  );
}
