import { redirect } from "react-router-dom";
import Paths from "@/constants/paths";
import useAuthStore from "@/stores/auth";
import type { User } from "@/schemas/user";

export async function impersonateLoader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const token = url.searchParams.get("token");
  const user = JSON.parse(decodeURIComponent(url.searchParams.get("user") ?? ''));

  if (!token) {
    return redirect(Paths.LOGIN);
  }

  const {login} = useAuthStore.getState();

  try {
    login(token, user as User);

    return redirect(Paths.COURSES);
  } catch (error) {
    return redirect(Paths.LOGIN);
  }
}
