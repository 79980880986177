import { z as zod } from "zod";

import labelSchema from "./label";

export const courseTagSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
  type: zod.union([
    zod.literal("direction"),
    zod.literal("duration"),
    zod.literal("level"),
  ]),
});

export type CourseTag = zod.infer<typeof courseTagSchema>;

export const coursesItemSchema = zod.object({
  id: zod.number(),
  url: zod.string(),
  name: zod.string(),
  short_description: zod.string().nullish(),
  labels: zod.array(labelSchema),
  filters: zod.array(courseTagSchema),
  need_to_open_entrance_test: zod.boolean().nullish(),
});

export type CoursesItem = zod.infer<typeof coursesItemSchema>;

export const courseCategorySchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  courses: zod.array(coursesItemSchema),
});

export type CourseCategory = zod.infer<typeof courseCategorySchema>;

export const coursesResponseSchema = zod.object({
  paidCourses: zod.array(coursesItemSchema),
  categories: zod.array(courseCategorySchema),
});
