import { useEffect, useState } from "react";

export default function useNow(updatePeriod = 1000 * 60) {
  const [now, setNow] = useState(() => new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, updatePeriod);

    return () => {
      clearInterval(interval);
    };
  }, [updatePeriod]);

  return now;
}
