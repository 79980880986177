import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

export default function useRequiredParam(name: string) {
  const { [name]: param } = useParams();
  invariant(param, `'${name}' param is required`);
  return param;
}

export function useCourseParam() {
  return useRequiredParam("course");
}
