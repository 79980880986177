import { z as zod } from "zod";

import userSchema from "./user";

export const verifyEmailResponseSchema = zod.union([
  zod
    .object({
      token: zod.string(),
      user: userSchema,
    })
    .nullable(),
  zod.array(zod.unknown()).transform(() => null),
]);
