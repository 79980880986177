import { Children, useState, type ReactElement, type ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { colors } from "@rls/styles/vars.css";

import TranslationNamespace from "@/constants/translation/namespace";

import HelpOption from "../option/HelpOption";

import * as styles from "./HelpOptions.css";

export interface HelpOptionsProps {
  onClose: () => void;
  children: Iterable<ReactNode> | ReactElement<{}>;
}

// NOTE: All children options will be displayed in reverse order!
export default function HelpOptions({ onClose, children }: HelpOptionsProps) {
  const { t } = useTranslation(TranslationNamespace.HELP);

  const [page, setPage] = useState(0);

  const pages = Children.toArray(children);

  return (
    <div className={styles.options}>
      <div onClick={() => onClose()} className={styles.page}>
        {pages[page]}
      </div>

      {page > 0 && (
        <HelpOption onClick={() => setPage((page) => page - 1)}>
          {t("previous")}
        </HelpOption>
      )}
      {page < pages.length - 1 && (
        <HelpOption onClick={() => setPage((page) => page + 1)}>
          {t("next")}
        </HelpOption>
      )}
      <HelpOption activeColor={colors.red.light} onClick={onClose}>
        {t("close")}
      </HelpOption>
    </div>
  );
}
