import { useEffect, useInsertionEffect, useRef } from "react";

export interface UseOutsideClickHandlerOptions {
  onClick: () => void;
  disabled?: boolean;
  ignore?: (target: Node) => boolean | undefined;
}

export default function useOutsideClickHandler({
  onClick,
  disabled,
  ignore,
}: UseOutsideClickHandlerOptions) {
  const ref = useRef<HTMLDivElement>(null);

  const onClickRef = useRef(onClick);
  const ignoreRef = useRef(ignore);

  useInsertionEffect(() => {
    onClickRef.current = onClick;
    ignoreRef.current = ignore;
  });

  ///

  useEffect(() => {
    if (disabled) {
      return;
    }

    let isOutside = false;

    const contains = (target: EventTarget | null) => {
      const { current: trap } = ref;

      return (
        !!trap &&
        target instanceof Node &&
        (trap.contains(target) || (ignoreRef.current?.(target) ?? false))
      );
    };

    const onDown = ({ target }: MouseEvent) => {
      isOutside = !contains(target);
    };

    const onUp = (e: MouseEvent) => {
      const { target } = e;
      if (isOutside && !contains(target)) {
        onClickRef.current();
      }

      isOutside = false;
    };

    document.addEventListener("mousedown", onDown);
    document.addEventListener("mouseup", onUp);

    return () => {
      document.removeEventListener("mousedown", onDown);
      document.removeEventListener("mouseup", onUp);
    };
  }, [disabled]);

  return ref;
}
