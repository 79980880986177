import { z as zod } from "zod";

import { zodDate } from "@/schemas/helpers";

import { courseMenuTaskModuleArraySchema } from "./task-module";

const courseMenuCohortSchema = zod.object({
  id: zod.number(),
  cohort_name: zod.string(),
  course_version_name: zod.string(),
  last_visit: zodDate,
  webinars_are_available: zod.boolean(),
});

export const courseMenuSchema = zod.object({
  course_name: zod.string(),
  statuses: zod.object({
    test_is_enabled: zod.boolean().nullable(),
    test_is_mandatory: zod.boolean().nullable(),
    course_is_paid: zod.boolean(),
    test_is_passed: zod.boolean().nullable(),
    is_super_user: zod.boolean().nullable(),
    has_chat_integration: zod.boolean().nullish(),
  }),
  cohorts: zod.array(courseMenuCohortSchema),
  default_cohort: zod.number().nullish(),
  task_modules: courseMenuTaskModuleArraySchema.optional(),
  show_pay_button: zod.boolean().nullish(),
});

export type CourseMenu = zod.infer<typeof courseMenuSchema>;

export type CourseMenuCohort = zod.infer<typeof courseMenuCohortSchema>;
