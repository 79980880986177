import { Trans, useTranslation } from "react-i18next";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";

import CustomError from "../custom-error/CustomError";

import { actionButton } from "./NetworkError.css";

export default function NetworkError() {
  const { t } = useTranslation(TranslationNamespace.ERROR, {
    keyPrefix: TranslationKeyPrefix.ERROR_NETWORK,
    useSuspense: false,
  });

  return (
    <CustomError
      title={t("page")}
      heading={t("title")}
      text={
        <Trans
          t={t}
          i18nKey="text"
          components={{
            "refresh-button": (
              <button
                className={actionButton}
                onClick={() => window.location.reload()}
              />
            ),
          }}
        />
      }
    />
  );
}
