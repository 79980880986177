import { useMemo, useState } from "react";

import { cx } from "classix";
import { useTranslation } from "react-i18next";

import { Headline, Text } from "@rls/ui-kit";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import HTTPWithDataError from "@/errors/http/HTTPWithDataError";
import type { PropsWithClassName } from "@/utils/props";

import * as styles from "./ErrorInfo.css";

export interface ErrorInfoProps extends PropsWithClassName {
  error: unknown;
}

export default function ErrorInfo({ error, className }: ErrorInfoProps) {
  const { t } = useTranslation(TranslationNamespace.ERROR, {
    keyPrefix: TranslationKeyPrefix.ERROR_STACK,
  });

  const [isOpen, setIsOpen] = useState(false);

  const details = useMemo(() => {
    if (error instanceof HTTPWithDataError) {
      return JSON.stringify(error.data, undefined, 2);
    }

    if (error instanceof Error) {
      return error.stack?.split("\n").filter(Boolean);
    }

    return undefined;
  }, [error]);

  if (!(error instanceof Error)) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      <Text
        as="button"
        variant="navigation-primary"
        className={styles.summary({ open: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {t("summary")}
      </Text>

      {isOpen && (
        <div className={styles.content}>
          <Headline variant="h3">
            {error.name}: {error.message}
          </Headline>

          {!!details && (
            <pre className={styles.stack}>
              <code>
                {typeof details === "string"
                  ? details
                  : details.map((text, i) => <div key={i}>{text}</div>)}
              </code>
            </pre>
          )}
        </div>
      )}
    </div>
  );
}
