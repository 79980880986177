// import { StrictMode } from "react";
import "./theme";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SlotsProvider } from "nano-slots";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { Provider as WrapBalancerProvider } from "react-wrap-balancer";

import queryClient from "@/clients/query";
import MainSpinner from "@/components/spinners/main/MainSpinner";
import { MenuStateProvider } from "@/context/menu-state/MenuStateContext";

import routes from "./routes";
import { sentryCreateBrowserRouter } from "./sentry";

import "./globals";
import "./i18n";
// eslint-disable-next-line import/no-unresolved
import "unfonts.css";
import "./global.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "@rls/scoped-markdown-components/fonts.css";

const router = sentryCreateBrowserRouter(routes);

import("./imprint");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.querySelector("#root")!).render(
  // <StrictMode>
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <MenuStateProvider>
        <SlotsProvider>
          <WrapBalancerProvider>
            <RouterProvider router={router} fallbackElement={<MainSpinner />} />
            <ReactQueryDevtools initialIsOpen={false} />
          </WrapBalancerProvider>
        </SlotsProvider>
      </MenuStateProvider>
    </HelmetProvider>
  </QueryClientProvider>
  // </StrictMode>
);
