import { z as zod } from "zod";

const courseParametersIntegrationConfigEntry = zod.object({
  name: zod.string(),
  value: zod.string(),
  type: zod.union([
    zod.literal("string"),
    zod.literal("link"),
    zod.literal("password"),
  ]),
});

export type CourseParametersIntegrationConfigEntry = zod.infer<
  typeof courseParametersIntegrationConfigEntry
>;

export const courseParametersIntegrationConfig = zod.array(
  courseParametersIntegrationConfigEntry
);

export type CourseParametersIntegrationConfig = zod.infer<
  typeof courseParametersIntegrationConfig
>;

const courseParametersUniversalIntegration = zod.object({
  id: zod.number(),
  name: zod.string(),
  config: courseParametersIntegrationConfig,
});

export type CourseParametersUniversalIntegration = zod.infer<
  typeof courseParametersUniversalIntegration
>;

export const courseParametersSchema = zod.object({
  integrations: zod.array(courseParametersUniversalIntegration),
  chat: courseParametersIntegrationConfig.nullish(),
});
