import type { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import { ButtonArrows, Text } from "@rls/ui-kit";

import { slideLeftRight as ctSlideLeftRight } from "@rls/styles/transitions.css";

import useScrollLock from "@/hooks/scroll/useScrollLock";
import { addTransitionEndListener } from "@/utils/transition";

import * as styles from "./MobileWindow.css";

export interface MobileWindowProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export default function MobileWindow({
  isOpen,
  onClose,
  children,
}: MobileWindowProps) {
  const { t } = useTranslation();

  useScrollLock(isOpen);

  return (
    <CSSTransition
      in={isOpen}
      classNames={ctSlideLeftRight}
      addEndListener={addTransitionEndListener}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Text
            variant="buttons"
            as="button"
            color="inherit"
            className={styles.button}
            onClick={() => onClose()}
          >
            <ButtonArrows arrows="before">{t("back")}</ButtonArrows>
          </Text>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
}
