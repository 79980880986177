import { cx } from "classix";
import { useTranslation } from "react-i18next";

import { Text } from "@rls/ui-kit";

import CloseIcon from "@/assets/new/button/clear-simple.svg?component";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";

import { useCloseModal } from "../../hooks";
import ModalLayoutBase, {
  type ModalLayoutBaseProps,
} from "../base/ModalLayoutBase";

import * as styles from "./ModalLayoutSimple.css";

export interface ModalLayoutSimpleProps extends ModalLayoutBaseProps {
  contentClassName?: string;
  wrapperClassName?: string;
}

export default function ModalLayoutSimple({
  children,
  modalClassName,
  contentClassName,
  wrapperClassName,
}: ModalLayoutSimpleProps) {
  const { t } = useTranslation(TranslationNamespace.MODAL, {
    keyPrefix: TranslationKeyPrefix.MODAL_SERVICE,
  });

  const close = useCloseModal();

  return (
    <ModalLayoutBase modalClassName={modalClassName}>
      <div className={cx(styles.wrapper, wrapperClassName)}>
        <button
          className={styles.close}
          onClick={() => close()}
          title={t("close")}
        >
          <CloseIcon />
        </button>

        <Text
          as="div"
          variant="navigation-primary"
          color="default"
          className={cx(styles.content, contentClassName)}
        >
          {children}
        </Text>
      </div>
    </ModalLayoutBase>
  );
}
