import QuestionSvg from "@rls/icons/question";

import * as styles from "./HelpOpener.css";

export interface HelpOpenerProps {
  onClick?: () => void;
}

export default function HelpOpener({ onClick }: HelpOpenerProps) {
  return (
    <button
      className={styles.opener}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}
      type="button"
    >
      <QuestionSvg className={styles.icon} />
    </button>
  );
}
