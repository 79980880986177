import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import Paths from "@/constants/paths";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import MenuLink from "@/layouts/menu/default/link/MenuLink";

export interface AccountMenuCoursesBlockProps {
  courses: Array<{
    id: number;
    name: string;
    url: string;
  }>;
}

export default function AccountMenuCoursesBlock({
  courses,
}: AccountMenuCoursesBlockProps) {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_COURSES,
  });

  if (courses.length === 0) {
    return null;
  }

  return (
    <SidebarMenu.Section heading={t("heading")}>
      {courses.map(({ id, name, url }) => (
        <SidebarMenu.Button
          key={id}
          as={MenuLink}
          to={generatePath(Paths.COURSE, { course: url })}
        >
          {name}
        </SidebarMenu.Button>
      ))}
    </SidebarMenu.Section>
  );
}
