import useOptionalCourseInfo from "./useOptionalCourseInfo";

export default function useCourseInfo() {
  const context = useOptionalCourseInfo();

  if (!context) {
    throw new Error(
      "[CourseInfoContext] component not wrapped with context provider"
    );
  }

  return context;
}
