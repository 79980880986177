import { z as zod } from "zod";

export const zodDate = zod.union([
  zod.coerce.date(),
  // NOTE: fallback to another date format for Safari
  zod
    .string()
    .transform((value) => value.replace(" ", "T"))
    .pipe(zod.coerce.date()),
]);
