import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import { useTheme } from "@/stores/theme";

export default function DefaultMenuLayoutThemeToggle() {
  const { theme, toggle } = useTheme();

  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_MENU,
  });

  return (
    <SidebarMenu.Button onClick={toggle}>
      {t("change_theme", {
        theme: t(
          match(theme)
            .with("dark", () => "night")
            .with("light", () => "day")
            .exhaustive()
        ),
      })}
    </SidebarMenu.Button>
  );
}
