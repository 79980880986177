import commonApi from "@/api/common";
interface setSearchParams {
  (searchParams: URLSearchParams, obj: any): any;
}

interface AssociativeArray {
   [key: string]: string
}


export function useSearchUtmParams(searchParams: URLSearchParams, setSearchParams: setSearchParams) {

  const utmLocalKey = 'rebrainUtmLocalStorage';
  const localUtmParams: string = localStorage.getItem(utmLocalKey) || '';

  let currentUtmParams: AssociativeArray = {};
  let currentParams: AssociativeArray = {};
  searchParams.forEach((value, key) => {
    if (key.indexOf('utm_') === 0) {
      currentUtmParams[key] = value;
    } else {
      currentParams[key] = value;
    }
  });

  if (Object.keys(currentUtmParams).length > 0) {
    const currentUtmParamsJson = JSON.stringify(currentUtmParams);
    if (currentUtmParamsJson != localUtmParams) {
      localStorage.setItem(utmLocalKey, currentUtmParamsJson);
      commonApi.saveUserUtm(currentUtmParams);
    }
  } else if (localUtmParams) {
    try {
      const searchParams = Object.assign(new URLSearchParams, currentParams, JSON.parse(localUtmParams));
      if (Object.keys(searchParams).length > 0) {
        setSearchParams({...searchParams}, { replace: true });
      } else {
        localStorage.removeItem(utmLocalKey);
      }
    } catch (e) {
      localStorage.removeItem(utmLocalKey);
    }

  }
}
