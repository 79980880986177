import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Chip, Text } from "@rls/ui-kit";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useNow from "@/features/headless-calendar/hooks/useNow";
import {
  isSameDay,
  yesterday,
} from "@/features/headless-calendar/utils/datetime";
import type { Notification } from "@/schemas/notifications";

import useMarkRead from "../../hooks/useMarkRead";

import * as styles from "./NotificationsEntry.css";

export type NotificationsEntryProps = Notification;

export default function NotificationsEntry({
  id,
  title,
  is_new: isNew,
  course,
  cohort,
  category,
  thread_link: link,
  updated_at: date,
}: NotificationsEntryProps) {
  const { t, i18n } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_NOTIFICATIONS,
  });

  const locale = i18n.resolvedLanguage ?? "ru";

  const time = useMemo(
    () =>
      date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
      }),
    [date, locale]
  );

  const relativeFormat = useMemo(() => {
    return new Intl.RelativeTimeFormat(locale, {
      numeric: "auto",
    });
  }, [locale]);

  const now = useNow();

  const day = useMemo(() => {
    if (isSameDay(date, now)) {
      return relativeFormat.format(0, "day");
    }

    if (isSameDay(date, yesterday(now))) {
      return relativeFormat.format(-1, "day");
    }

    return date.toLocaleDateString(locale, {
      day: "2-digit",
      month: "2-digit",
      year: now.getFullYear() === date.getFullYear() ? undefined : "numeric",
    });
  }, [date, locale, now, relativeFormat]);

  const markRead = useMarkRead({
    course: course.url,
  });

  return (
    <li className={styles.root}>
      <div className={styles.nameRow}>
        {isNew && (
          <Chip color="green|blue" className={styles.badge}>
            {t("new")}
          </Chip>
        )}
        <Text
          {...(link
            ? {
                as: "a",
                href: link,
                target: "_blank",
                rel: "noreferrer",
                hoverable: true,
                onClick: () => isNew && markRead({ id }),
              }
            : {})}
          variant="group-headline"
          color="headlines"
          className={styles.text}
        >
          {title}
        </Text>
      </div>

      <Text variant="navigation-secondary" className={styles.courseRow}>
        {course.name}, {cohort.name}
      </Text>

      <div className={styles.infoRow}>
        <Text variant="labels" color="navigation-additional">
          {t("type", { type: category })}
        </Text>

        <Text variant="navigation-secondary" color="navigation-additional">
          {time}, {day}
        </Text>
      </div>
    </li>
  );
}
