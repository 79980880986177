import { useCallback, useState, type ReactNode } from "react";

import { cx } from "classix";
import { CSSTransition } from "react-transition-group";

import OpenerIcon from "@rls/icons/arrows-left";
import { transition } from "@rls/styles/easing";
import { Breakpoints } from "@rls/styles/media";

import { appear as ctAppear } from "@rls/styles/transitions.css";

import { CloseDrawerMenuProvider } from "@/context/menu/close/CloseMenuContext";
import useScrollLock from "@/hooks/scroll/useScrollLock";
import useBreakpoint from "@/hooks/useBreakpoint";
import PageLayout from "@/layouts/page/PageLayout";
import { addTransitionEndListener } from "@/utils/transition";

import SidebarMenu from "../../menu/SidebarMenu";
import SidebarMenuLayoutHeading, {
  type SidebarMenuLayoutHeadingProps,
} from "../heading/SidebarMenuLayoutHeading";

import * as styles from "./SidebarMenuLayoutDesktop.css";

const noop = () => {};

export interface SidebarMenuLayoutDesktopProps {
  headline: SidebarMenuLayoutHeadingProps["render"];
  content: ReactNode;
}

export default function SidebarMenuLayoutDesktop({
  headline,
  content,
}: SidebarMenuLayoutDesktopProps) {
  const isAbsoluteMenu = useBreakpoint({
    min: Breakpoints.MD,
    max: styles.staticMenuMinWidth - 1,
  });

  const isStaticMenu = useBreakpoint({
    min: styles.staticMenuMinWidth,
  });

  const [isOpen, setIsOpen] = useState(isStaticMenu);

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useScrollLock(isAbsoluteMenu && isOpen);

  return (
    <CloseDrawerMenuProvider value={isAbsoluteMenu ? close : noop}>
      <PageLayout.Menu className={styles.sidebar}>
        {isAbsoluteMenu && (
          <CSSTransition
            in={isOpen}
            classNames={ctAppear}
            unmountOnExit
            mountOnEnter
            addEndListener={addTransitionEndListener}
            timeout={transition.ease300ms.duration}
          >
            <div className={styles.blur} onClick={() => setIsOpen(false)} />
          </CSSTransition>
        )}
        <div className={cx(styles.wrapper)}>
          <div className={styles.menu({ open: isOpen })}>
            <div className={styles.header}>
              <div className={styles.heading}>
                <SidebarMenuLayoutHeading
                  render={headline}
                  className={styles.headingInner}
                />
              </div>
              <button className={styles.opener} onClick={toggle}>
                <OpenerIcon
                  className={styles.openerIcon({ closed: !isOpen })}
                />
              </button>
            </div>
            <div className={styles.content({ closed: !isOpen })}>
              <div className={styles.contentInner}>
                <SidebarMenu>{content}</SidebarMenu>
              </div>
            </div>
          </div>
        </div>
      </PageLayout.Menu>
    </CloseDrawerMenuProvider>
  );
}
