import { useState } from "react";

import {
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import UserIcon from "@rls/icons/user";
import { Text } from "@rls/ui-kit";

import { flyDown as ctFlyDown } from "@rls/styles/transitions.css";

import Paths from "@/constants/paths";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useLogout from "@/layouts/menu/default/hooks/useLogout";
import { addTransitionEndListener } from "@/utils/transition";

import HeaderIconButton from "../../button/icon/HeaderIconButton";

import * as styles from "./HeaderUserMenu.css";

export default function HeaderUserMenu() {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_MENU,
  });

  const handleLogout = useLogout();

  ///

  const [isOpen, setIsOpen] = useState(false);

  const withCloseMenu = <T extends (...args: any[]) => unknown>(
    callback?: T
  ) => {
    return (...args: Parameters<T>) => {
      setIsOpen(false);
      return callback?.(...args);
    };
  };

  const { floatingStyles, context, refs } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    middleware: [offset(10), shift()],
    whileElementsMounted: autoUpdate,
    transform: false,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
    useClick(context),
  ]);

  ///

  return (
    <>
      <HeaderIconButton {...getReferenceProps()} ref={refs.setReference}>
        <UserIcon />
      </HeaderIconButton>

      <CSSTransition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        classNames={ctFlyDown}
        addEndListener={addTransitionEndListener}
      >
        <div
          ref={refs.setFloating}
          className={styles.wrapper}
          style={floatingStyles}
        >
          <ul {...getFloatingProps()} className={styles.menu}>
            <li>
              <Text
                as={Link}
                variant="secondary"
                className={styles.item}
                to={Paths.ACCOUNT}
                onClick={withCloseMenu()}
              >
                {t("settings")}
              </Text>
            </li>
            <li>
              <Text
                as="button"
                variant="secondary"
                className={styles.item}
                onClick={withCloseMenu(handleLogout)}
              >
                {t("logout")}
              </Text>
            </li>
          </ul>
        </div>
      </CSSTransition>
    </>
  );
}
