import { useId, type ReactNode } from "react";

import cx from "classix";
import {
  useFormContext,
  type FieldPathByValue,
  type FieldValues,
} from "react-hook-form";

import { Text } from "@rls/ui-kit";
import type { CustomFormField } from "@rls/ui-kit/utils/form";

import ToggleCheckboxIcon from "@/components/toggle-checkbox-icon/ToggleCheckboxIcon";

import * as styles from "./FormCheckboxField.css";

export type FormCheckboxFieldProps<TFields extends FieldValues> = Omit<
  CustomFormField<"input", TFields>,
  "gap" | "label" | "type" | "wrapper"
> & {
  name: FieldPathByValue<TFields, boolean>;
  labelClassName?: string;
  children: ReactNode;
};

// TODO: add to ui-kit
export default function FormCheckboxField<
  TFields extends FieldValues = FieldValues
>({
  className,
  name,
  options,
  fieldClassName,
  labelClassName,
  children,
  ...rest
}: FormCheckboxFieldProps<TFields>) {
  const { register } = useFormContext<TFields>();

  const id = useId();

  return (
    <div className={fieldClassName}>
      <input
        {...rest}
        type="checkbox"
        {...register(name, options)}
        id={id}
        hidden
      />
      <Text
        as="label"
        variant="secondary"
        color="default"
        className={cx(styles.label, fieldClassName)}
        htmlFor={id}
      >
        <ToggleCheckboxIcon className={cx(styles.checkbox, className)} />
        <span className={labelClassName}>{children}</span>
      </Text>
    </div>
  );
}
