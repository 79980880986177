import TranslationNamespace from "@/constants/translation/namespace";
import { preloadTranslation } from "@/utils/preload";

export default async function modalsLayoutLoader() {
  await Promise.all([
    preloadTranslation([
      TranslationNamespace.VALIDATION,
      TranslationNamespace.FORM,
    ]),
  ]);

  return null;
}
