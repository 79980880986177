import { z as zod } from "zod";

export const liveClassTagSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
});

export const liveClassTagsResponseSchema = zod.array(liveClassTagSchema);

export type LiveClassTag = zod.infer<typeof liveClassTagSchema>;
