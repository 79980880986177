import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ForwardRefExoticComponent,
  type ReactNode,
  type Ref,
} from "react";

import { cx } from "classix";

import RadioSvg from "@rls/icons/radio";
import { Text } from "@rls/ui-kit";

import ToggleCheckboxIcon from "@/components/toggle-checkbox-icon/ToggleCheckboxIcon";

import * as styles from "./DropdownRadio.css";

export interface DropdownRadioProps<TOption, TValue extends number | string>
  extends Omit<ComponentPropsWithoutRef<"ul">, "onSelect"> {
  options: TOption[];
  selectValue: (option: TOption) => TValue;
  selectLabel: (option: TOption) => ReactNode;

  selectedValue: TValue | undefined;

  onSelect: (value: TValue) => void;
}

const DropdownRadio: <TOption, TValue extends number | string>(
  props: DropdownRadioProps<TOption, TValue>,
  ref: Ref<HTMLUListElement>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => ReturnType<ForwardRefExoticComponent<DropdownRadioProps<any, any>>> =
  forwardRef<
    HTMLUListElement,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DropdownRadioProps<any, any>
  >(function DropdownRadio(
    {
      className,
      options,
      selectedValue: selected,
      onSelect,
      selectLabel,
      selectValue,
      ...rest
    },
    ref
  ) {
    return (
      <ul {...rest} ref={ref} className={cx(styles.dropdown, className)}>
        {options.map((option) => {
          const value = selectValue(option);
          const label = selectLabel(option);
          const isSelected = value === selected;

          return (
            <li key={value}>
              <Text
                as="button"
                variant="secondary"
                className={styles.dropdownItem}
                onClick={() => onSelect(value)}
                disabled={isSelected}
              >
                <ToggleCheckboxIcon as={RadioSvg} checked={isSelected} />
                <span>{label}</span>
              </Text>
            </li>
          );
        })}
      </ul>
    );
  });

export default DropdownRadio;
