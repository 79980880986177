import { useTranslation } from "react-i18next";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import useMenuState from "@/context/menu-state/useMenuState";

import HeaderTextButton from "../../button/text/HeaderTextButton";

export default function HeaderMobileContent() {
  const { t } = useTranslation(TranslationNamespace.HEADER, {
    keyPrefix: TranslationKeyPrefix.HEADER_MOBILE,
  });

  const { isOpen, toggle } = useMenuState();

  return (
    <HeaderTextButton onClick={toggle}>
      {t(isOpen ? "close" : "open")}
    </HeaderTextButton>
  );
}
