import CourseSectionMaterials from "@/features/course-menu/components/sections/materials/CourseMenuSectionMaterials";
import CourseMenuSectionProgram from "@/features/course-menu/components/sections/program/CourseMenuSectionProgram";

export default function SharedCourseMenu() {
  return (
    <>
      <CourseSectionMaterials />
      <CourseMenuSectionProgram />
    </>
  );
}
