export function extractRelativePathFromUrl(value: URL | string) {
  const url = typeof value === "string" ? new URL(value) : value;
  const { pathname, search, hash } = url;

  return `${pathname}${search}${hash}`;
}

export function urlWithCurrentSearch(
  targetUrl: URL | string,
  currentUrl: URL | string
) {
  const current = new URL(currentUrl);
  const target = new URL(targetUrl, current);

  if (target.search) {
    const params = new URLSearchParams(current.searchParams);

    target.searchParams.forEach((value, key) => {
      params.set(key, value);
    });

    target.search = `?${params.toString()}`;
  } else {
    target.search = current.search;
  }

  return extractRelativePathFromUrl(target);
}
