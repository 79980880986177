import type { QueryClient } from "@tanstack/react-query";
import type { InfiniteQueryHook, QueryHook } from "react-query-kit";

import i18n from "@/i18n";
import useAuthStore from "@/stores/auth";
import { tryHandleUnauthorized } from "@/utils/course/error";
import { parseErrorStatus } from "@/utils/fetch/parseErrorResponse";

export async function preloadFromQueryKit<TData, TVariables>(
  queryClient: QueryClient,
  query: QueryHook<TData, TVariables, unknown>,
  variables: TVariables
) {
  try {
    const key = query.getKey(variables as never);

    return await queryClient.ensureQueryData({
      queryKey: key,
      queryFn: query.queryFn,
    });
  } catch (error) {
    tryHandleUnauthorized(error);

    throw error;
  }
}

export async function forceLoadFromQueryKit<TData, TVariables>(
  queryClient: QueryClient,
  query: QueryHook<TData, TVariables, unknown>,
  variables: TVariables
) {
  try {
    const key = query.getKey(variables as never);

    return await queryClient.fetchQuery({
      queryKey: key,
      queryFn: query.queryFn,
    });
  } catch (error) {
    tryHandleUnauthorized(error);

    throw error;
  }
}

export async function preloadFromInfiniteQueryKit<
  TData extends object,
  // TData extends { nextPage: number | null },
  TVariables
>(
  queryClient: QueryClient,
  query: InfiniteQueryHook<TData, TVariables>,
  variables: TVariables
) {
  try {
    const key = query.getKey(variables as never);

    return (
      queryClient.getQueryData(key) ??
      (await queryClient.fetchInfiniteQuery({
        queryKey: key,
        queryFn: query.queryFn,
      }))
    );
  } catch (error) {
    tryHandleUnauthorized(error);

    throw error;
  }
}

export const preloadTranslation = i18n.loadNamespaces.bind(i18n);

export function tryIgnoreUnauthorized(err: unknown) {
  if (parseErrorStatus(err) === 401) {
    useAuthStore.getState().logout();
    return undefined;
  }

  throw err;
}
