import type { PropsWithChildren } from "react";

import { Text } from "@rls/ui-kit";

import * as styles from "./MenuTaskEmpty.css";

export default function MenuTaskEmpty({ children }: PropsWithChildren) {
  return (
    <Text
      variant="navigation-secondary"
      color="default"
      className={styles.empty}
    >
      {children}
    </Text>
  );
}
