import { kyFetcher } from "@/api/fetcher";
import { scriptsSchema } from "@/schemas/scripts";

let injected = false;

export function canInjectScripts() {
  return !injected;
}

function injectScripts(scripts: string[]) {
  for (const html of scripts) {
    const range = document.createRange();
    range.selectNodeContents(document.body);
    const fragment = range.createContextualFragment(html);

    document.body.append(fragment);
  }
}

async function loadScripts() {
  const data = await kyFetcher
    .get("scripts", {
      retry: 3,
    })
    .json();
  return scriptsSchema.parse(data).scripts;
}

export default async function customScripts() {
  if (!canInjectScripts()) {
    return;
  }

  injected = true;

  try {
    const scripts = await loadScripts();
    injectScripts(scripts);
  } catch {
    /* empty */
  }
}
