import type { ReactNode } from "react";

import RebrainLogo from "@rls/icons/logo";
import { Headline } from "@rls/ui-kit";

import ErrorLayout from "@/layouts/error/ErrorLayout";

import * as styles from "./CustomError.css";

export interface CustomErrorProps {
  title: string;

  heading?: ReactNode;
  text?: ReactNode;
  info?: ReactNode;
}

export default function CustomError({
  title: page,
  heading: title,
  text,
  info,
}: CustomErrorProps) {
  return (
    <ErrorLayout pageTitle={page} contentClassName={styles.content}>
      <div>
        <RebrainLogo className={styles.logo} />
        <Headline variant="h2" color="headlines" className={styles.title}>
          {title}
        </Headline>
        <Headline variant="h3" as="p" color="headlines" className={styles.text}>
          {text}
        </Headline>
      </div>
      {info}
    </ErrorLayout>
  );
}
