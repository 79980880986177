import type { RecipeVariants } from "@vanilla-extract/recipes";
import { BarLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";

import { appear as ctAppear } from "@rls/styles/transitions.css";
import { colors } from "@rls/styles/vars.css";

import { addTransitionEndListener } from "@/utils/transition";

import * as styles from "./RefetchSpinner.css";

export type RefetchSpinnerProps = RecipeVariants<typeof styles.spinner> & {
  isActive?: boolean;
};

/**
 * NOTE: if you're using this spinner with filters and table,
 * don't forget to set zIndex to 4 to prevent the spinner from being
 * overlapping with the filters dropdown
 */
export default function RefetchSpinner({
  isActive = true,
  position,
}: RefetchSpinnerProps) {
  return (
    <CSSTransition
      in={isActive}
      classNames={ctAppear}
      addEndListener={addTransitionEndListener}
      mountOnEnter
      unmountOnExit
    >
      <BarLoader
        color={colors.aqua.light}
        height={2}
        width="100%"
        className={styles.spinner({ position })}
      />
    </CSSTransition>
  );
}
