import type { ComponentType } from "react";

import getDisplayName from "./react/getDisplayName";

export interface WithConditionOptions {
  ifFalse?: "children" | "null";
}

export default function withCondition<TProps extends object>(
  Component: ComponentType<TProps>,
  checker: (props: TProps) => boolean,
  { ifFalse = "null" }: WithConditionOptions = {}
) {
  function WithCondition(props: TProps) {
    if (!checker(props)) {
      return ifFalse === "children" && "children" in props
        ? // eslint-disable-next-line react/destructuring-assignment
          (props.children as JSX.Element)
        : null;
    }

    return <Component {...props} />;
  }

  WithCondition.displayName = `WithCondition(${getDisplayName(Component)})`;

  return WithCondition;
}
