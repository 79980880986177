import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenuLayout from "@/features/sidebar-menu-layout/SidebarMenuLayout";
import { useAccountCoursesQuery } from "@/queries/account";
import type { UnwrapLoader } from "@/utils/preload/types";
import selectDataFromResponse from "@/utils/query/selectDataFromResponse";

import AccountMenuShared from "./components/shared/AccountMenuShared";
import type loader from "./loader";

import SidebarSectionGeneral from "../sidebar-menu-layout/components/sections/general/SidebarSectionGeneral";

export default function AccountMenuLayout() {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU);

  const initialData = useLoaderData() as UnwrapLoader<typeof loader>;

  const { data } = useAccountCoursesQuery({
    variables: {},
    initialData,
    select: selectDataFromResponse,
  });

  return (
    <SidebarMenuLayout
      headline={({ className }) => (
        <h3 className={className()}>
          {t(`${TranslationKeyPrefix.SIDEBAR_HEADLINE}.account`)}
        </h3>
      )}
      desktop={
        <>
          <AccountMenuShared courses={data} />
          <SidebarSectionGeneral />
        </>
      }
      mobile={{
        sections: <AccountMenuShared courses={data} />,
        before: <SidebarSectionGeneral headless />,
      }}
    />
  );
}
