import { useParams } from "react-router-dom";

import { useHelpWidgetInfoQuery } from "@/queries/help";
import { useUserSelectedCohort } from "@/stores/user-selected-cohort";

import HelpContent from "./components/content/HelpContent";

export default function Help() {
  const { course } = useParams();

  const cohort = useUserSelectedCohort(course ?? "");

  const { data } = useHelpWidgetInfoQuery({
    variables: { course, cohort },
  });

  if (!data) {
    return null;
  }

  return <HelpContent data={data} />;
}
