import { useMemo } from "react";

import { Fill } from "nano-slots";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";

import RefetchSpinner from "@/components/spinners/refetch/RefetchSpinner";
import Paths from "@/constants/paths";
import Slots from "@/constants/slots";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenuButton from "@/features/sidebar-menu-layout/components/menu/button/SidebarMenuButton";
import SidebarMenuLayout from "@/features/sidebar-menu-layout/SidebarMenuLayout";
import useResolvedLanguage from "@/hooks/i18n/useResolvedLanguage";
import { useCourseParam } from "@/hooks/params";
import HeaderTextButton from "@/layouts/base/header/button/text/HeaderTextButton";
import MenuLink from "@/layouts/menu/default/link/MenuLink";
import { useCourseMenuQuery } from "@/queries/course";
import { useUserSelectedCohortStore } from "@/stores/user-selected-cohort";

import CourseMenuHeadline from "./components/headline/CourseMenuHeadline";
import CourseMenuHeadlineTitle from "./components/headline/title/CourseMenuHeadlineTitle";
import SharedCourseMenu from "./components/shared/SharedCourseMenu";
import { CohortSelectorStateProvider } from "./context/cohort-selector-state/CohortSelectorStateContext";
import { CourseInfoProvider } from "./context/course-info/CourseInfoContext";
import { CourseSelectedCohortInfoProvider } from "./context/selected-cohort-info/CourseSelectedCohortInfoContext";
import { CourseTaskLookupProvider } from "./context/task-lookup/CourseTaskLookupContext";
import type { CourseMenuLoaderData } from "./courseMenuLoader";

export default function CourseMenuLayout() {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_COMMON,
  });

  const lang = useResolvedLanguage();

  const course = useCourseParam();

  const initialData = useLoaderData() as CourseMenuLoaderData;

  const selectedCohort = useUserSelectedCohortStore(
    ({ selected }) => selected[course]
  );

  const { data, isPreviousData } = useCourseMenuQuery({
    variables: { url: course, cohort: selectedCohort },
    initialData,
  });

  const sortedCohorts = useMemo(() => {
    const comparer = new Intl.Collator(lang, {
      numeric: true,
    });

    return [...data.cohorts].sort((left, right) => {
      return (
        comparer.compare(left.course_version_name, right.course_version_name) ||
        comparer.compare(left.cohort_name, right.cohort_name)
      );
    });
  }, [data.cohorts, lang]);

  return (
    <>
      <Fill name={Slots.HEADER_BUTTONS}>
        <HeaderTextButton as={Link} to={Paths.COURSES}>
          {t("all_practicums")}
        </HeaderTextButton>
      </Fill>

      <CourseInfoProvider value={data}>
        <CourseTaskLookupProvider>
          <CourseSelectedCohortInfoProvider>
            <CohortSelectorStateProvider>
              <SidebarMenuLayout
                headline={({ className }) => (
                  <CourseMenuHeadline cohorts={sortedCohorts}>
                    <RefetchSpinner
                      isActive={isPreviousData}
                      position="absolute"
                    />
                    <CourseMenuHeadlineTitle className={className} />
                  </CourseMenuHeadline>
                )}
                desktop={
                  <>
                    <SharedCourseMenu />
                  </>
                }
                mobile={{
                  sections: <SharedCourseMenu />,
                  after: (
                    <SidebarMenuButton as={MenuLink} to={Paths.COURSES}>
                      {t("all_practicums")}
                    </SidebarMenuButton>
                  ),
                }}
              />
            </CohortSelectorStateProvider>
          </CourseSelectedCohortInfoProvider>
        </CourseTaskLookupProvider>
      </CourseInfoProvider>
    </>
  );
}
