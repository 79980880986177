import { z as zod } from "zod";

const userSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  theme: zod.union([zod.literal("dark"), zod.literal("light")]).nullish(),
});

export default userSchema;

export type User = zod.infer<typeof userSchema>;
