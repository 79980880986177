import type { BeforeRequestHook } from "ky";

import useAuthStore from "@/stores/auth";

export const kyInjectAuth: BeforeRequestHook = (request) => {
  const token = useAuthStore.getState()._auth;

  if (token) {
    request.headers.set("Authorization", `Bearer ${token}`);
  }
};
