import { useTranslation } from "react-i18next";

import Paths from "@/constants/paths";
import TranslationKeyPrefix from "@/constants/translation/keyPrefix";
import TranslationNamespace from "@/constants/translation/namespace";
import SidebarMenu from "@/features/sidebar-menu-layout/components/menu/SidebarMenu";
import MenuLink from "@/layouts/menu/default/link/MenuLink";

export default function AccountMenuSectionsBlock() {
  const { t } = useTranslation(TranslationNamespace.SIDEBAR_MENU, {
    keyPrefix: TranslationKeyPrefix.SIDEBAR_SECTIONS,
  });

  return (
    <SidebarMenu.Section heading={t("heading")}>
      <SidebarMenu.Button as={MenuLink} to={Paths.ACCOUNT}>
        {t("general")}
      </SidebarMenu.Button>
      <SidebarMenu.Button notReady as={MenuLink} to={Paths.ACCOUNT_REWARDS}>
        {t("rewards")}
      </SidebarMenu.Button>
      <SidebarMenu.Button as={MenuLink} to={Paths.ACCOUNT_PAYMENTS}>
        {t("payments")}
      </SidebarMenu.Button>
      <SidebarMenu.Button as={MenuLink} to={Paths.ACCOUNT_CERTIFICATES}>
        {t("certificates")}
      </SidebarMenu.Button>
    </SidebarMenu.Section>
  );
}
