import { z as zod } from "zod";

import { courseFeatureSchema } from "@/schemas/feature";
import { zodDate } from "@/schemas/helpers";
import { labelsArraySchema } from "@/schemas/label";

const cohortSchema = zod.object({
  course_name: zod.string(),
  course_version_name: zod.string(),
  description: zod.string().nullable(),
  features: zod.array(courseFeatureSchema),
  id: zod.number(),
  labels: labelsArraySchema,
  name: zod.string(),
  opening_date: zodDate,
  payment_button_text: zod.string().nullish(),
  price: zod.number(),
  discount: zod.number().nullish(),
  installment_monthly_payment: zod.number().nullish(),
  has_live_classes: zod.boolean().nullish(),
});

export const selectCohortSchema = zod.array(cohortSchema);

export type Cohort = zod.infer<typeof cohortSchema>;
