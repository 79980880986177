import { kyFetcher } from "@/api/fetcher";
import type LiveClassStatus from "@/features/live-classes/constants/statuses";
import { allLiveClassesPageSchema } from "@/schemas/course/live-classes/all";
import { liveClassesCalendarSchema } from "@/schemas/course/live-classes/calendar";
import { liveClassTagsResponseSchema } from "@/schemas/course/live-classes/tags";
import { upcomingLiveClassesArraySchema } from "@/schemas/course/live-classes/upcoming-events";
import { coursePlaylistSpeakersResponseSchema } from "@/schemas/course/videos";
import { successfulResponse } from "@/schemas/response";
import {
  createInfiniteQuery,
  createQueryWithSchema,
} from "@/utils/fetch/createQueryFunction";

export const useLiveClassCalendarQuery = createQueryWithSchema(
  successfulResponse(liveClassesCalendarSchema)
)<{ course: string; cohort?: number }>({
  primaryKey: "course/live-classes/calendar",
  queryFn: async ({ queryKey: [, { course, cohort = -1 }], signal }) => {
    return await kyFetcher
      .get(`courses/${course}/cohorts/${cohort}/webinars/calendar`, { signal })
      .json();
  },
});

export const useLiveClassesUpcomingEventsQuery = createQueryWithSchema(
  successfulResponse(upcomingLiveClassesArraySchema)
)<{ course: string; cohort?: number }>({
  primaryKey: "course/live-classes/upcoming",
  queryFn: async ({ queryKey: [, { course, cohort = -1 }], signal }) => {
    return await kyFetcher
      .get(`courses/${course}/cohorts/${cohort}/webinars/upcoming`, { signal })
      .json();
  },
});

export const useLiveClassesQuery = createInfiniteQuery<{
  course: string;
  cohort?: number;
  tags?: Array<number | string>;
  speakers?: Array<number | string>;
  name?: string | null | undefined;
  statuses?: LiveClassStatus[];

  sortBy?: string | null | undefined;
  sortType?: string | null | undefined;
}>()({
  primaryKey: "course/live-classes/all",
  // getNextPageParam: (page) =>
  queryFn: async ({
    pageParam = 1,
    queryKey: [
      ,
      { course, cohort = -1, statuses, tags, speakers, name, sortBy, sortType },
    ],
    signal,
  }) => {
    const search = new URLSearchParams();

    statuses?.forEach((status) => {
      search.append("statuses[]", String(status));
    });

    tags?.forEach((tag) => {
      search.append("tags[]", String(tag));
    });

    speakers?.forEach((speakers) => {
      search.append("speakers[]", String(speakers));
    });

    if (name) {
      search.append("name", name);
    }

    if (sortBy) {
      search.set("_sort", sortBy);
    }

    if (sortType) {
      search.set("_order", sortType);
    }

    search.set("page", String(pageParam));

    return successfulResponse(allLiveClassesPageSchema).parse(
      await kyFetcher(`courses/${course}/cohorts/${cohort}/webinars`, {
        searchParams: search,
        signal,
      }).json()
    );
  },
  getNextPageParam: ({ nextPage }) => {
    return nextPage ?? undefined;
  },
});

export const useLiveClassesSpeakersQuery = createQueryWithSchema(
  successfulResponse(coursePlaylistSpeakersResponseSchema)
)<{
  course: string;
  cohort?: number;
}>({
  primaryKey: "course/live-classes/speakers",
  queryFn: async ({ queryKey: [, { course, cohort = -1 }], signal }) => {
    return kyFetcher(`courses/${course}/cohorts/${cohort}/webinars/speakers`, {
      signal,
    }).json();
  },
});

export const useLiveClassesTagsQuery = createQueryWithSchema(
  successfulResponse(liveClassTagsResponseSchema)
)<{
  course: string;
  cohort?: number;
}>({
  primaryKey: "course/live-classes/tags",
  queryFn: async ({ queryKey: [, { course, cohort = -1 }], signal }) => {
    return kyFetcher(`courses/${course}/cohorts/${cohort}/webinars/tags`, {
      signal,
    }).json();
  },
});
