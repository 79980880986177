import NiceModal from "@ebay/nice-modal-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHref, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { match } from "ts-pattern";
import { z as zod } from "zod";

import { FormSubmit, Headline } from "@rls/ui-kit";

import { kyFetcher } from "@/api/fetcher";
import TranslationNamespace from "@/constants/translation/namespace";
import { useCloseModal } from "@/features/modals/hooks";
import ModalLayoutSimple from "@/features/modals/layout/simple/ModalLayoutSimple";
import { requiredStringValidation } from "@/validation/rules";

import HelpQuestionModalEditor from "./editor/HelpQuestionModalEditor";

import useInvalidateConversations from "../../hooks/useInvalidateConversations";

import * as styles from "./HelpQuestionModal.css";

const schema = zod.object({
  question: requiredStringValidation,
});

export interface HelpQuestionModalProps {
  target: "coordinator" | "tutor";
  cohort: number;
  info?: unknown;
}

const HelpQuestionModal = NiceModal.create<HelpQuestionModalProps>(
  function HelpQuestionModal({ target, cohort, info }) {
    const { t } = useTranslation(TranslationNamespace.HELP);

    const close = useCloseModal();

    const href = useHref(useLocation());

    const form = useForm({
      mode: "onTouched",
      resolver: zodResolver(schema),
    });

    const invalidateConversations = useInvalidateConversations();

    const onSubmit = async (data: object) => {
      const backendTarget = target === "tutor" ? "curator" : target;

      try {
        await kyFetcher.post(
          `cohorts/${cohort}/send-${backendTarget}-question`,
          {
            json: {
              ...data,
              page: href,
              info,
            },
          }
        );
        invalidateConversations();
        close();
        toast.success(t("question_success"));
      } catch {
        toast.error(t("question_error"));
      }
    };

    return (
      <ModalLayoutSimple
        wrapperClassName={styles.wrapper}
        contentClassName={styles.content}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
          <Headline variant="h2" as="h3" className={styles.headline}>
            {t(
              match(target)
                .with("tutor", () => "tutor_question")
                .with("coordinator", () => "coordinator_question")
                .exhaustive()
            )}
          </Headline>

          <FormProvider {...form}>
            <HelpQuestionModalEditor name="question" />

            <FormSubmit containerClassName={styles.button} width="custom">
              {t("ask_question")}
            </FormSubmit>
          </FormProvider>
        </form>
      </ModalLayoutSimple>
    );
  }
);

export default HelpQuestionModal;
