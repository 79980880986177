import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export interface ReCaptchaWrapperProps {
  children: JSX.Element;
}

export default function ReCaptchaWrapper({ children }: ReCaptchaWrapperProps) {
  const key = import.meta.env.APP_RECAPTCHA_V3_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={key}>
      {children}
    </GoogleReCaptchaProvider>
  );
}
