import { useTranslation } from "react-i18next";

import TranslationNamespace from "@/constants/translation/namespace";

import HelpOption from "../../option/HelpOption";

export interface HelpCommonOptionsProps {
  chat: string | null | undefined;
  channel: string | null | undefined;
}

export default function HelpCommonOptions({
  chat,
  channel,
}: HelpCommonOptionsProps) {
  const { t } = useTranslation(TranslationNamespace.HELP);

  return (
    <>
      {!!chat && (
        <HelpOption as="a" href={chat} target="_blank" rel="noreferrer">
          {t("common_chat")}
        </HelpOption>
      )}
      {!!channel && (
        <HelpOption as="a" href={channel} target="_blank" rel="noreferrer">
          {t("channel")}
        </HelpOption>
      )}
    </>
  );
}
