import { z as zod } from "zod";

const accountSettingsSchema = zod.object({
  id: zod.number(),
  name: zod.string().nullable(),
  english_name: zod.string().nullable(),
  phone: zod.string().nullable(),
  email: zod.string().nullable(),
  tg_id: zod.string().nullable(),
  company: zod.string().nullable(),
  position: zod.string().nullable(),

  name_verify: zod.boolean().nullable(),
  english_name_verify: zod.boolean().nullable(),

  need_to_check_data: zod.boolean().nullable(),

  timezone: zod
    .object({
      name: zod.string(),
      shift: zod.string(),
    })
    .nullable(),
});

export default accountSettingsSchema;

export type AccountSettingsSchema = zod.infer<typeof accountSettingsSchema>;
