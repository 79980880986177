import { useState } from "react";

import { CSSTransition, SwitchTransition } from "react-transition-group";

import { semiFade } from "@rls/styles/transitions.css";

import useOptionalCourseInfo from "@/features/course-menu/context/course-info/useOptionalCourseInfo";
import useOutsideClickHandler from "@/hooks/useOutsideClickHandler";
import type { HelpWidgetInfo } from "@/schemas/help";
import { addTransitionEndListener } from "@/utils/transition";

import HelpOpener from "../opener/HelpOpener";
import HelpOptions from "../options/HelpOptions";
import HelpCommonOptions from "../predefined/common/HelpCommonOptions";
import HelpCourseOptions from "../predefined/course/HelpCourseOptions";

import * as styles from "./HelpContent.css";

export interface HelpContentProps {
  data: HelpWidgetInfo;
}

export default function HelpContent({
  data: { chat_url, channel_url, cohort_chat_url },
}: HelpContentProps) {
  const [expanded, setExpanded] = useState(false);

  const hasChatIntegration =
    useOptionalCourseInfo()?.statuses.hasChatIntegration;

  const ref = useOutsideClickHandler({
    disabled: !expanded,
    onClick: () => {
      setExpanded(false);
    },
  });

  const options = [
    hasChatIntegration && (
      <HelpCourseOptions key="course" chat={cohort_chat_url ?? undefined} />
    ),
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    chat_url || channel_url ? (
      <HelpCommonOptions key="general" chat={chat_url} channel={channel_url} />
    ) : null,
  ].filter((page): page is JSX.Element => !!page);

  if (options.length === 0) {
    return null;
  }

  return (
    <div ref={ref} className={styles.help}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={String(expanded)}
          addEndListener={addTransitionEndListener}
          classNames={semiFade}
        >
          <div>
            {expanded ? (
              <HelpOptions
                onClose={() => {
                  setExpanded(false);
                }}
              >
                {options}
              </HelpOptions>
            ) : (
              <HelpOpener
                onClick={() => {
                  setExpanded(true);
                }}
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
