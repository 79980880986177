import { z as zod } from "zod";

import { zodDate } from "@/schemas/helpers";
import { labelsArraySchema } from "@/schemas/label";
import { videoSpeakerSchema } from "@/schemas/video";

import { liveClassTopicSchema } from "./item";

export const upcomingLiveClassSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  performance_date: zodDate,
  labels: labelsArraySchema,
  speakers: zod.array(videoSpeakerSchema.omit({ description: true })),
  themes: zod.array(liveClassTopicSchema).nullish(),
});

export type UpcomingLiveClass = zod.infer<typeof upcomingLiveClassSchema>;

export const upcomingLiveClassesArraySchema = zod.array(
  upcomingLiveClassSchema
);
