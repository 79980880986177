import { createContext, useMemo, type PropsWithChildren } from "react";

import { useCourseParam } from "@/hooks/params";
import { useUserSelectedCohortStore } from "@/stores/user-selected-cohort";
import isNullish from "@/utils/checks/isNullish";

import useCourseInfo from "../course-info/useCourseInfo";

export type ICourseSelectedCohortInfoContext =
  | {
      id: number;
      name: string;
      courseVersionName: string;
      hasLiveClasses: boolean;
    }
  | undefined;

export const CourseSelectedCohortInfoContext =
  createContext<ICourseSelectedCohortInfoContext>(undefined);

export function CourseSelectedCohortInfoProvider({
  children,
}: PropsWithChildren) {
  const { cohorts, defaultCohort } = useCourseInfo();
  const course = useCourseParam();

  const selectedCohort = useUserSelectedCohortStore(
    (state) => state.selected[course]
  );
  // const changeCohort = useSelectedCohort((state) => state.change);

  const data = useMemo<ICourseSelectedCohortInfoContext>(() => {
    const findCohort = (cohortId: number | null | undefined) => {
      if (isNullish(cohortId)) {
        return undefined;
      }

      return cohorts.find(({ id }) => id === cohortId);
    };

    const cohort = findCohort(selectedCohort) ?? findCohort(defaultCohort);

    if (!cohort) {
      return undefined;
    }

    return {
      id: cohort.id,
      name: cohort.cohort_name,
      courseVersionName: cohort.course_version_name,
      hasLiveClasses: cohort.webinars_are_available,
    };
  }, [cohorts, defaultCohort, selectedCohort]);

  // useEffect(() => {
  //   if (!data) {
  //     return;
  //   }

  //   const { id } = data;

  //   if (!isNullish(selectedCohort) && id !== selectedCohort) {
  //     changeCohort(course, id);
  //   }
  // }, [changeCohort, course, data, selectedCohort]);

  return (
    <CourseSelectedCohortInfoContext.Provider value={data}>
      {children}
    </CourseSelectedCohortInfoContext.Provider>
  );
}
