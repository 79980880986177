import i18next from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
// import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const appLanguage = import.meta.env.APP_I18N_LANG || "ru";

void i18next
  // TODO: load from backend
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      backends: [
        // LocalStorageBackend,
        {
          type: "backend",
          init: () => {},
          read(
            language: string,
            namespace: string,
            callback: (errorValue: unknown, translations: unknown) => void
          ) {
            import(`../translations/${language}/${namespace}.json`)
              .then((resources) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                callback(null, resources.default);
              })
              .catch((error) => {
                console.warn("error", error);
                callback(error, null);
              });
          },
        },
      ],
    },

    lng: appLanguage, // if you're using a language detector, do not define the lng option
    // fallbackLng: "ru",
    debug: import.meta.env.DEV,

    supportedLngs: [appLanguage],
    preload: [appLanguage],

    returnNull: false,
    fallbackLng: false,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export { default } from "i18next";
