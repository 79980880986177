import { createMutation } from "react-query-kit";
import { match } from "ts-pattern";

import { kyFetcher } from "@/api/fetcher";
import ValidationError from "@/errors/validation/ValidationError";
import { accountCertificatesArraySchema } from "@/schemas/account/certificates";
import accountSettingsSchema from "@/schemas/account/settings";
import { accountVerificationSchema } from "@/schemas/account/verification";
import { verifyEmailResponseSchema } from "@/schemas/email";
import { successfulResponse } from "@/schemas/response";
import type { ApiResponse, ApiSuccessResponse } from "@/types/api";
import {
  createQuery,
  createQueryWithSchema,
} from "@/utils/fetch/createQueryFunction";
import parseErrorResponse, {
  parseErrorMessage,
} from "@/utils/fetch/parseErrorResponse";
import { getVisitorId } from "@/utils/fingerprint";

export const useAccountQuery = createQueryWithSchema(
  successfulResponse(accountSettingsSchema)
)({
  primaryKey: "account/user",
  queryFn: async () => {
    return await kyFetcher.get("account/user").json();
  },
});

export const useAccountMutation = createMutation({
  mutationFn: async (data: Record<string, unknown>) => {
    try {
      return await kyFetcher
        .post("account", {
          json: {
            ...data,
            visitorId: await getVisitorId(),
          },
        })
        .json();
    } catch (error) {
      const message = parseErrorMessage(error);

      throw match(message)
        .with("INVALID_NAME", () => new ValidationError("name", "name_invalid"))
        .with(
          "INVALID_ENGLISH_NAME",
          () => new ValidationError("english_name", "english_name_invalid")
        )
        .with(
          "USER_WITH_EMAIL_ALREADY_EXISTS",
          () => new ValidationError("email", "user_with_email_already_exists")
        )
        .with(
          "INVALID_EMAIL",
          () => new ValidationError("email", "user_with_email_already_exists")
        )
        .with(
          "PHONE_ALREADY_EXISTS",
          () => new ValidationError("phone", "phone_already_registered")
        )
        .with(
          "WRONG_CURRENT_PASSWORD",
          () => new ValidationError("current_password", "wrong_password")
        )
        .with(
          "TELEGRAM_ID_ALREADY_EXISTS",
          () =>
            new ValidationError("tg_id", "user_with_telegram_already_exists")
        )
        .otherwise(() => error);
    }
  },
});

export const useAccountCoursesQuery = createQuery()({
  primaryKey: "account/courses",
  queryFn: async () => {
    return await kyFetcher.get("account/courses").json<
      ApiSuccessResponse<
        Array<{
          id: number;
          name: string;
          url: string;
          labels: Array<{
            id: number;
            text: string;
            type: string;
          }>;
          filters: Array<{
            id: number;
            text: string;
            type: string;
          }>;
        }>
      >
    >();
  },
});

export const useAccountTimezonesQuery = createQuery()({
  primaryKey: "account/timezones",
  queryFn: async () => {
    const { data } = await kyFetcher
      .get("account/timezones")
      .json<ApiSuccessResponse<Array<{ name: string; shift: string }>>>();
    return data;
  },
});

export const useAccountPaymentsQuery = createQuery()({
  primaryKey: "account/payments",
  queryFn: async () => {
    const { data } = await kyFetcher.get("account/payments").json<
      ApiSuccessResponse<
        Array<{
          cohort_id: number;
          id: number;
          name: string;
          cohort_name: string;
          url: string;
          short_description: string;
          labels: [];
          filters: [];
          price: number;
          is_installment: boolean;
          installment_month_count?: number;
        }>
      >
    >();
    return data;
  },
});

export const useAccountCertificatesQuery = createQueryWithSchema(
  successfulResponse(accountCertificatesArraySchema)
)({
  primaryKey: "account/certificates",
  queryFn: async () => {
    return await kyFetcher.get("certificates").json();
  },
});

export const useAccountVerificationQuery = createQueryWithSchema(
  successfulResponse(accountVerificationSchema)
)({
  primaryKey: "account/verification",
  queryFn: async () => {
    return await kyFetcher.get("account/verification").json();
  },
});

export const useAccountSendEmailVerificationMutation = createMutation({
  mutationFn: async () => {
    return await kyFetcher.post("send-verification-mail");
  },
});

export const useVerifyEmailMutation = createMutation({
  mutationFn: async (data: { token: string }) => {
    const response = await kyFetcher
      .post("verify-email", {
        json: data,
      })
      .json();

    return successfulResponse(verifyEmailResponseSchema).parse(response);
  },

  retry: (count, error) => {
    return (
      count <= 3 && parseErrorResponse<ApiResponse>(error)?.success !== false
    );
  },
});
